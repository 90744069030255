import { useState } from 'react';
import { connect } from 'react-redux';
import { LoadingImg } from '../_components';
import { messageConstant } from '../_constants';
import { employerService, miscService } from '../_services';

const InviteEmployerPopup = props => {
  const [inviteesDetails, setInviteesDetails] = useState([
    { company_name: '', phone: '', email: '' },
  ]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companyNameValue, setCompanyNameValue] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);

  const handleChange = (index, e) => {
    let { name, value } = e.target;
    let numericValue = value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters

    // Copy the existing invitees state
    const updatedInvitees = [...inviteesDetails];
    const updatedErrors = [...errors]; // Copy the errors state to an array
    let tempError = { ...updatedErrors[index], isError: false }; // Initialize the error state for this index

    // Company Name Validation
    if (name === 'company_name') {
      value = value.toUpperCase();
      if (!value.trim()) {
        tempError.company_name = messageConstant.COMPANY_REQUIRED;
        tempError.isError = true;
        setCompanyNameValue(false);
      } else {
        tempError.company_name = '';
        setCompanyNameValue(true); // Clear error if input is valid
      }
    }

    // Phone Validation
    if (name === 'phone') {
      // Check if the numeric value needs a leading zero
      if (numericValue.charAt(0) !== '0' && numericValue.length === 10) {
        // Add a zero at the beginning of the numeric value
        numericValue = '0' + numericValue;
      }
      // Now check the length with the updated numeric value
      if (numericValue.charAt(0) === '0' && numericValue.length > 11) {
        return;
      } else if (numericValue.length > 11) {
        return;
      }
      // Update the specific invitee's phone with the numeric value
      updatedInvitees[index][name] = numericValue;
    } else {
      // Update the specific invitee's field with the regular value
      updatedInvitees[index][name] = value;
    }

    // Update the invitees state
    setInviteesDetails(updatedInvitees);

    // Update error state for the specific index
    if (tempError.isError) {
      updatedErrors[index] = tempError;
    } else {
      // Clear the specific error if the input is valid
      updatedErrors[index] = { ...updatedErrors[index], [name]: '' };
    }

    setErrors(updatedErrors);
  };

  const handleSendInvitee = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const inviteEmployer = await employerService.inviteEmployer(
        inviteesDetails,
      );
      if (inviteEmployer.error_code === 0) {
        setIsLoading(false);
        props.setShowModal(false);
        setInviteesDetails([{ email: '', company_name: '', phone: '' }]);
      }
    } catch (error) {
      setIsLoading(false);
      setErrors(prevErrors => ({
        ...prevErrors,
        common: [
          ...(prevErrors.common || []),
          messageConstant.EMAIL_ALREADY_USED,
        ],
      }));
    }
  };

  const handleCheckEmail = async (index, emailValue) => {
    const isValidEmail = miscService.emailVerify(emailValue);

    if (!isValidEmail) {
      // Update errors for the specific index
      setErrors(prevErrors => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = {
          email: messageConstant.INVALID_EMAIL,
          isError: true,
        };
        return updatedErrors;
      });
      return;
    } else {
      // Clear error for the specific index if email is valid
      setErrors(prevErrors => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = { email: '', isError: false };
        return updatedErrors;
      });
    }

    try {
      await employerService.isEmailExist(emailValue);
      setEmailVerify(true);
    } catch (error) {
      // Set error for the specific index if email already exists
      setEmailVerify(false);
      setErrors(prevErrors => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = {
          email: messageConstant.EMAIL_ALREADY_USED,
          isError: true,
        };
        return updatedErrors;
      });
      return;
    }
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    props.setShowModal(false); // Close the modal
    setInviteesDetails([{ email: '', company_name: '', phone: '' }]); // Reset invitee details
  };

  const handleAddAnother = () => {
    setInviteesDetails([
      ...inviteesDetails,
      { company_name: '', email: '', phone: '' },
    ]);
  };
  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content flx-save-employer-popup-text">
        <div className="modal-header justify-content-end mt-3">
          <button
            type="button"
            className="close flx-closebutton-popup"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseModal}
          >
            <span aria-hidden="true" className="flx-closeicon">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body invite-employer">
          <h5 className="justify-content-center" id="modalLabel">
            Send Invitation
          </h5>
          <p className="">
            This will send an invitation your potential client to enrol on the
            platform
          </p>
          <div>
            {inviteesDetails?.map((invitee, index) => (
              <form key={index}>
                <div className="mt-3">
                  <label
                    htmlFor={`Companyname-${index}`}
                    className="form-label company-info-labels flx-recs-required"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control flx-comp-settings-form-control"
                    id={`Companyname-${index}`}
                    placeholder="Invitee company name"
                    name="company_name"
                    onChange={e => handleChange(index, e)}
                    value={invitee.company_name}
                  />
                  <div className="help-block">
                    {errors[index] && errors[index].company_name}
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="form-group col-md-6">
                    <label
                      htmlFor={`email-${index}`}
                      className="form-label company-info-labels flx-recs-required"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      onBlur={() => handleCheckEmail(index, invitee.email)}
                      className="form-control flx-comp-settings-form-control"
                      id={`email-${index}`}
                      placeholder="Invitee email"
                      name="email"
                      onChange={e => handleChange(index, e)}
                      value={invitee.email}
                    />
                    <div className="help-block">
                      {errors[index] && errors[index].email}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div>
                      <label
                        htmlFor={`phone-${index}`}
                        className="form-label company-info-labels"
                      >
                        Phone
                      </label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="flx-basic-addon1"
                          >
                            +44
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control flx-comp-settings-form-control"
                          placeholder="09999 999 999"
                          aria-label="Phone number"
                          aria-describedby="basic-addon1"
                          name="phone"
                          // onBlur={() => handlePhone(index, invitee.phone)}
                          onChange={e => handleChange(index, e)}
                          value={invitee.phone}
                          ref={input => {
                            if (input) {
                              input.style.setProperty(
                                '::placeholder',
                                'color: #ccc; opacity: 1;',
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="help-block">
                        {errors[index] && errors[index].phone}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ))}
            <div className="help-block">{errors.common}</div>
          </div>

          {isLoading && <LoadingImg />}

          {inviteesDetails.length < 2 && (
            <button
              onClick={handleAddAnother}
              className=" m-auto"
              disabled={
                !inviteesDetails[0].company_name ||
                !inviteesDetails[0].email ||
                !emailVerify
              }
            >
              <i className="flx-add-button-invite-employer fa fa-plus-circle">
                <span className="flx-add-employer-invite-text">Add</span>
              </i>
            </button>
          )}
        </div>
        <div className="flx-modal-invite-employer-popup-bottom send-invite-btn">
          <button
            type="button"
            className="btn btn-sm flx-popup-button"
            style={{ padding: '5px' }}
            onClick={handleSendInvitee}
            disabled={!emailVerify || !companyNameValue}
          >
            Send Invitation
          </button>

          <a
            className="flx-popup-email-change-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, employerData } = state.employer;
  return {
    requestStatus,
    employerData,
  };
}

const connectedShiftsAdd = connect(mapStateToProps)(InviteEmployerPopup);
export { connectedShiftsAdd as InviteEmployerPopup };
