import moment from 'moment';
import styles from './AllShiftList.module.css';

import React, { useEffect, useRef, useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { AgencyCandidateProfile } from '../AgencyCandidate/AgencyCandidateProfile';
import { AttendaceBloackbooking } from '../Block_booking/AttendanceBloackbooking';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { InvoiceAdd } from '../Invoice';
import { PayoutAdd2 } from '../Payout';
import {
  businessunitActions,
  employerActions,
  shiftsActions,
} from '../_actions';
import { LoadingImg, PaginationNew } from '../_components';
import { apiConstants, roleConstant } from '../_constants';
import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';
import { miscService, shiftsService } from '../_services';
import { ShiftEdit } from './ShiftEdit';
import { Shifts } from './Shifts';
import { ShiftsAdd } from './ShiftsAdd';
let employer_id = '';
let user_role = '';
const UNVERIFIED = 'unverified';

const AllShiftsList = props => {
  let { id } = useParams();
  const dropdownRef = useRef(null);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
  const [allSelectedShiftIds, setAllSelectedShiftIds] = useState([]);
  const [allEmployers, setAllEmployers] = useState([]);
  const [selectedShiftsId, setSelectedShiftsId] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedEmployer, setSelectedEmployer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [shiftverificationmodal, setshiftverificationmodal] = useState(false);
  const [showshiftAddmodal, setShowshiftAddmodal] = useState(false);
  const [timesheetmodal, setTimesheetModal] = useState(false);
  const [showshiftEditmodal, setShowshiftEditmodal] = useState(false);
  const [deleteconfirmationmodal, setDeleteConfirmationModal] = useState(false);
  const [cancelconfirmationmodal, setCancelConfirmationModal] = useState(false);
  const [restoreconfirmationmodal, setRestoreConfirmationModal] =
    useState(false);
  const [shiftid, setShiftid] = useState([]);
  const [restoredetails, setRestoreDetails] = useState([]);
  const [canceltype, setcanceltype] = useState();
  const [selectedShifts, setSelectedShifts] = useState({});
  const [allselectedpages, setallselectedpages] = useState([]);
  const [showInvoicemodal, setshowInvoicemodal] = useState(false);
  const [showPayoutmodal, setshowPayoutmodal] = useState(false);
  const [trashChecked, settrashChecked] = useState(false);
  const [cancelChecked, setcancelChecked] = useState(false);
  const [view_shift_id, Setview_shift_id] = useState();
  const [editShiftId, seteditShiftId] = useState();
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);
  const [isBunit, setisBunit] = useState(false);
  const [isParentEmployer, setisParentEmployer] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isCancelLoading, setisCancelLoading] = useState(false);
  const [businessUnitFilterRows, setBusinessUnitFilterRows] = useState([]);
  const [organizationRows, setorganizationRows] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectAllClicked, setSelectAllClicked] = useState(false);
  const [selectAllShifts, setSelectAllShifts] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 20,
    },
  });
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [showAgencyCandidateprofileModal, setshowAgencyCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [allBusinessUnits, setAllBusinessUnits] = useState([]);
  const [filteredEmployers, setFilteredEmployers] = useState([]);

  const [isFromDateFocused, setIsFromDateFocused] = useState(false);
  const [isToDateFocused, setIsToDateFocused] = useState(false);
  // const [filteredBusinessUnits, setFilteredBusinessUnits] = useState([]);
  const dispatch = useDispatch();
  //--- debouncing for searchkey only - start ---
  let isfirsttime = useRef(true);
  const debouncedSearchFromDate = useDebounce(query?.from_date, 500);
  const debouncedSearchToDate = useDebounce(query?.to_date, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearchFromDate || debouncedSearchToDate) {
        getAllShifts(1);
      }
    }
  }, [debouncedSearchFromDate, debouncedSearchToDate]);
  //--- debouncing end ---
  // Handle focus and blur events
  // effect for get all shifts according to query change
  useEffect(() => {
    if (id) {
      if (id == UNVERIFIED) {
        query.is_verified = 0;
      }
    }
    if (props && props.id) {
      query.candidate_id = props.id;
    }
    if (props && props.from_date) {
      query.from_date = props.from_date;
    }
    if (props && props.to_date) {
      query.to_date = props.to_date;
    }
    if (props && props.employer_id) {
      query.employer_id = props.employer_id;
    }
    if (props && props.candidate_id) {
      query.candidate_id = props.candidate_id;
    }
    if (props && props.agency_id) {
      query.agency_id = props.agency_id;
    }
    if (props && props.agency_candidate_id) {
      query.agency_candidate_id = props.agency_candidate_id;
    }
    if (props && props.is_verified) {
      query.is_verified = props.is_verified;
    }
    if (props && props.status) {
      query.status = props.status;
    }
    if (props && props.shift_status) {
      query.shift_status = props.shift_status;
    }
    if (props && props.category) {
      query.category = props.category;
    }
    if (props && props.businessunit) {
      query.business_unit = props.businessunit;
    }
    getAllShifts(1);
  }, [
    query.pageVo.sort_order,
    query.pageVo.sort,
    query.employer_id,
    query.shift_status,
    query.candidate_id,
    query.agency_id,
    query.agency_candidate_id,
    query.is_verified,
    query.status,
    query.category,
    query.business_unit,
    query.is_attended,
    query.invoice_generated,
    query.branch,
    query.business_unit,
    isAdmin,
    isStaff,
  ]);
  // effect for get all shifts according to selected shift id change
  useEffect(() => {
    getAllShifts(query.pageVo.pageNo);
  }, [/*selectedShifts.length,*/ query]);
  // effect for get employer and business unit
  //when change on isAdmin, isStaff, isBunit state change
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      user_role = user.role;
      if (user && user.employer_id) {
        employer_id = user.employer_id;
        if (user.is_parent) {
          setisParentEmployer(true);
          getAllParentEmployer();
        }
        // getAllBusinessUnit(user.employer_id);
        getBusinessUnits(user.employer_id);
      }
      if (user.role == roleConstant.ADMIN) {
        setisAdmin(true);
      }
      if (user.role == roleConstant.STAFF) {
        setisStaff(true);
      }
      if (user.role == roleConstant.BUSINESS_UNIT) {
        setisBunit(true);
      }
    }
  }, [isAdmin, isStaff, isBunit]);

  // show invoice modal open or close function
  const showInvoiceModalfunction = value => {
    setshowInvoicemodal(value);
  };
  // show payout modal open or close function
  const showPayoutModalfunction = value => {
    setshowPayoutmodal(value);
  };
  // used for handling modal close or open function in AttendaceBloackbooking component
  const showTimesheetModalfunction = value => {
    setTimesheetModal(value);
    getAllShifts(query.pageVo.pageNo);
    setSelectedShifts({});
  };

  useEffect(() => {
    getBusinessUnits();
    getEmployers();
  }, []);

  const handleFromDateFocus = () => setIsFromDateFocused(true);
  const handleFromDateBlur = () => setIsFromDateFocused(false);

  const handleToDateFocus = () => setIsToDateFocused(true);
  const handleToDateBlur = () => setIsToDateFocused(false);

  // handler for filters like from_date ,to_date ,is_verified ,is_attended ,
  // invoice_generated ,branch, business_unit,organization_id

  const handleChange = event => {
    const { name, value } = event.target;
    let updatedQuery = { ...query, [name]: value };
    let fromDate;
    let toDate;
    if (name === 'from_date' || name === 'to_date') {
      fromDate =
        name === 'from_date' ? new Date(value) : new Date(query.from_date);
      toDate = name === 'to_date' ? new Date(value) : new Date(query.to_date);
    }
    updatedQuery = {
      ...query,
      from_date: fromDate,
      to_date: toDate,
    };

    isfirsttime.current = false;
    setQuery({ ...query, [name]: value });
    if (query.from_date > query.to_date) {
      alert('From date cannot be greater than To date');
      updatedQuery = { ...query, [name]: '' };
      setQuery(updatedQuery);
      // setQuery({ ...query, to_date: '', from_date: '' });
      return;
    }
    getBusinessUnits(value, updatedQuery.from_date, updatedQuery.to_date);
    getEmployers(value, updatedQuery.from_date, updatedQuery.to_date);
  };

  // loader for employer list in async select
  const getEmployers = (inputvalue, from_date, to_date) =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getemployers', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          searchKey: inputvalue,
          from_date: from_date,
          to_date: to_date,
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            if (data && data.rows && data.rows.length > 0) {
              let options = data.rows.map(({ _id, company_name }) => ({
                value: _id,
                label: company_name,
              }));
              resolve(options);
              setAllEmployers(options);
            } else {
              reject([]);
            }
          }),
        )
        .catch(reject);
    });

  // get all business unit for filter based on Employer
  const getAllFilteredEmployers = business_unit => {
    return new Promise(resolve => {
      dispatch(
        employerActions.employerslistforshift({
          business_unit:
            business_unit && business_unit
              ? business_unit.join(',')
              : business_unit,
          status: 1,
          select: ['_id', 'company_name'],
        }),
      ).then(res => {
        let allData = res.data.rows;
        const options =
          allData &&
          allData.map(unit => ({
            value: unit._id,
            label: unit.company_name,
          }));
        setFilteredEmployers(options);
        // setBusinessUnitFilterRows(options);
        resolve(options);
      });
    });
  };

  const setEmployer = selectedOption => {
    // setSelectedShifts({});

    const selectedValues = selectedOption
      ? selectedOption.map(option => option.value)
      : [];
    setQuery(prevState => ({
      ...prevState,
      employer_id: selectedValues,
    }));

    // setInputValue(
    //   selectedOption && selectedOption[0] ? selectedOption[0].label : '',
    // );
    setSelectedEmployer(selectedOption);
    getAllBusinessUnitOptions(selectedValues);
  };

  // get all employers for filter
  const getAllParentEmployer = () => {
    const { dispatch } = props;
    let newquery = {
      status: 1,
      is_parent: 0,
      getChildEmployers: true,
      select: ['_id', 'company_name'],
    };
    dispatch(employerActions.employerListv2(newquery)).then(res => {
      if (res) {
        let parentArray = [];
        for (let s = 0; s < res?.data?.rows?.length; s++) {
          parentArray.push(
            <option value={res?.data?.rows?.[s]._id}>
              {res?.data?.rows?.[s].company_name}
            </option>,
          );
        }
        setorganizationRows(parentArray);
      }
    });
  };

  // get shift list
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (employer_id) {
      queryNew.employer_id = employer_id;
    }
    if (props?.organization_id) {
      queryNew.employer_id = props?.organization_id;
    }
    if (props && props.category) {
      query.organization_id = employer_id;
    }
    if (id == UNVERIFIED) {
      queryNew.is_verified = 0;
    }
    if (queryNew.organization_id && queryNew.organization_id == 'null') {
      delete query.organization_id;
    }
    if (queryNew.is_verified == '') {
      delete queryNew.is_verified;
    }
    if (queryNew.branch == '') {
      delete queryNew.branch;
    }
    if (query.business_unit == null || query.business_unit == '') {
      delete query.business_unit;
    }
    if (query.employer_id == null || query.employer_id == '') {
      delete query.employer_id;
    }
    if (props?.parent_id) {
      queryNew.employer_id = props?.parent_id;
    }
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        for (
          let s = 0;
          res.data && res.data.rows && s < res?.data?.rows?.length;
          s++
        ) {
          res.data.rows[s].x = 1;
        }
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  // shift comfirm action call
  const confirmshiftverification = () => {
    const { dispatch } = props;
    dispatch(
      shiftsActions.updateShift({
        _id: shiftverificationmodal.id,
        is_accepted: 1,
      }),
    ).then(function () {
      setshiftverificationmodal({ id: '', modal: false });
      getAllShifts(query.pageVo.pageNo);
    });
  };

  // get all business units
  const getBusinessUnits = (inputvalue, from_date, to_date) =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getbusinessunitlistforshift', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          searchKey: inputvalue,
          from_date: from_date,
          to_date: to_date,
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            if (data && data.rows && data.rows.length > 0) {
              const options = data.rows.map(({ _id, name }) => ({
                value: _id,
                label: name,
              }));
              setAllBusinessUnits(options);
              // setBusinessUnitFilterRows(options);
              resolve(options);
            } else {
              reject([]);
            }
          }),
        )
        .catch(reject);
    });

  // get all business unit for filter based on Employer
  const getAllBusinessUnitOptions = employer_id => {
    return new Promise(resolve => {
      dispatch(
        businessunitActions.businessunitList({
          employer_id:
            employer_id && employer_id ? employer_id.join(',') : employer_id,

          status: 1,
          select: ['_id', 'name'],
        }),
      ).then(res => {
        let allData = res.data.rows;
        const options =
          allData &&
          allData.map(unit => ({
            value: unit._id,
            label: unit.name,
          }));
        setBusinessUnitFilterRows(options);
        resolve(options);
      });
    });
  };

  // handle business units
  const handleBusinessUnits = selectedOptions => {
    const selectedUnits = selectedOptions
      ? selectedOptions?.map(unit => unit.value)
      : [];
    setQuery(prevState => ({
      ...prevState,
      business_unit: selectedUnits,
    }));
    setSelectedBusinessUnit(selectedOptions);
    getAllFilteredEmployers(selectedUnits);
  };

  // add shift modal open or close action
  const showAddshiftmodal = value => {
    setShowshiftAddmodal(value);
  };

  // to select candidate  from filter
  const selectedcandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      agency_id: 0,
    }));
  };

  // show delete shift modal show
  // const deleteShiftModalShow = shift_id => {
  //   setShiftid(shift_id);
  //   setDeleteConfirmationModal(true);
  // };

  // to cancel shift modal
  const cancelShiftModalShow = shift_id => {
    setShiftid(shift_id);
    setCancelConfirmationModal(true);
  };

  // restore shift modal
  const restoreShiftModalShow = restoredata => {
    setRestoreDetails({
      candidate_id: restoredata.candidate_id,
      section_type: restoredata.section_type,
      _id: restoredata._id,
      check_in_date_string: restoredata.check_in_date_string,
    });
    setRestoreConfirmationModal(true);
  };

  // delete shift function
  const deleteshift = () => {
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsDelete({ _id: shiftid })).then(res => {
      if (res) {
        setDeleteConfirmationModal(false);
        getAllShifts(query.pageVo.pageNo);
      }
    });
  };

  // set all selected pages
  const checkAllHandle = isCheck => {
    if (isCheck) {
      setallselectedpages(prev => [...prev, query.pageVo.pageNo]);
      for (let c = 0; c < shiftsData.rows.length; c++) {
        if (shiftsData.rows[c]._id) {
          selectedShifts[shiftsData.rows[c]._id] = shiftsData.rows[c];
        }
      }
    } else {
      setallselectedpages(
        allselectedpages.filter(page => {
          return page !== query.pageVo.pageNo;
        }),
      );
      for (let c = 0; c < shiftsData.rows.length; c++) {
        if (selectedShifts[shiftsData.rows[c]._id]) {
          delete selectedShifts[shiftsData.rows[c]._id];
        }
      }
      //  deleteElements(new_selectedShiftIds);
    }
    // const count = Object.keys(selectedShifts).length;
    //setShiftidsCount(count);
    setSelectedShifts({ ...selectedShifts });
    setListAndPagination(shiftsData);
    setSelectAllClicked(isCheck);
  };

  // handle check select item in row
  const handleSelectShift = (shift_id, shift, shiftsData) => {
    if (selectedShifts[shift_id]) {
      delete selectedShifts[shift_id];
    } else {
      selectedShifts[shift_id] = shift;
    }
    // setShiftidsCount(Object.keys(selectedShifts).length);
    setSelectedShifts(selectedShifts);
    setListAndPagination(shiftsData);
  };

  const category_change = selectedOptions => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : [];
    setSelectedCategories(selectedOptions);
    setQuery(prevState => ({
      ...prevState,
      category: selectedValues,
    }));
  };

  // hander for agency filter selection
  const agency_change = selectedOption => {
    setQuery(prevState => ({
      ...prevState,
      agency_id: selectedOption.value,
      candidate_id: 0,
    }));
  };

  // to cancel shift confirmation action and a get all shifts call
  const cancelshift = () => {
    const { dispatch } = props;
    let newquery = {};
    setSubmitted(true);
    if (shiftid && canceltype) {
      setisCancelLoading(true);
      newquery._id = shiftid;
      newquery.cancel_type = canceltype;
      dispatch(shiftsActions.shiftsCancel(newquery)).then(res => {
        if (res) {
          setisCancelLoading(false);
          setCancelConfirmationModal(false);
          getAllShifts(query.pageVo.pageNo);
        }
      });
      setSubmitted(false);
    } else {
      setisCancelLoading(false);
    }
  };
  // to restore shift action and get all shifts call
  const restoreshift = () => {
    const { dispatch } = props;
    setSubmitted(true);
    if (restoredetails) {
      let newquery = restoredetails;
      dispatch(shiftsActions.shiftsRestore(newquery)).then(res => {
        if (res) {
          setRestoreConfirmationModal(false);
          getAllShifts(query.pageVo.pageNo);
        }
      });
      setSubmitted(false);
    }
  };
  // edit shift modal handler for ShiftEdit component
  const showEditshiftmodal = () => {
    setShowshiftEditmodal(false);
    getAllShifts(1);
  };
  // handler for candidate select
  const selectedallocatecandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      agency_id: 0,
    }));
  };

  const editShift = shift_id => {
    seteditShiftId(shift_id);
    setShowshiftEditmodal(true);
  };

  // set shift list and pagination
  const setListAndPagination = shiftsData => {
    let shiftsList = [];
    const currentTime = moment();

    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      const checkInTime = moment(shiftsData.rows[j].check_in_date);

      const checkOutTime = moment(shiftsData.rows[j].check_out_date);
      const candidateFirstName = shiftsData.rows[j]['Candidate.name'];
      const candidateLastName = shiftsData.rows[j]['Candidate.last_name'];
      const fullName =
        candidateFirstName && candidateLastName
          ? `${candidateFirstName} ${candidateLastName}`
          : candidateFirstName || candidateLastName || '';

      const displayName =
        fullName.length > 8 ? `${fullName.substring(0, 12)}...` : fullName;

      shiftsList.push(
        <tr key={j} className="row-body2">
          {!props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.isHistory &&
            !props.businessunit &&
            !props.category &&
            !props.organization_id && (
              <td>
                {shiftsData.rows[j].status == 1 && (
                  <div
                    className="pointer"
                    onClick={() =>
                      handleSelectShift(
                        shiftsData.rows[j]._id,
                        shiftsData.rows[j],
                        shiftsData,
                      )
                    }
                  >
                    {selectedShifts[shiftsData.rows[j]._id] ? (
                      <img
                        className="check_style"
                        src="/assets/img/check1.png"
                      />
                    ) : (
                      <img
                        className="check_style"
                        src="/assets/img/un_check1.png"
                      />
                    )}
                  </div>
                )}
              </td>
            )}
          {!props.isHistory && (
            <td>
              {shiftsData.rows[j]['Candidate.name'] && (
                <div
                  onClick={() => {
                    setSelectedCandidateId(shiftsData.rows[j].candidate_id);
                    setshowCandidateprofileModal(true);
                  }}
                  className=""
                >
                  <div
                    style={{
                      color: 'black',
                      fontSize: '12px',
                      flexDirection: 'row',
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    {shiftsData.rows[j]['Candidate.profileimage'] ? (
                      <img
                        className="round-emp-image"
                        src={
                          apiConstants.imgUrl +
                          shiftsData.rows[j]['Candidate.profileimage']
                        }
                      />
                    ) : (
                      <img
                        className=" round-emp-image"
                        src="/assets/img/recsy-fav-icon.png"
                      />
                    )}
                    <p className="username" title={fullName}>
                      {displayName}
                    </p>
                  </div>
                </div>
              )}
              {shiftsData.rows[j].agency_candidate_id &&
                shiftsData.rows[j]['Agencycandidate.name'] && (
                  <div
                    onClick={() => {
                      setSelectedCandidateId(
                        shiftsData.rows[j].agency_candidate_id,
                      ),
                        setshowAgencyCandidateprofileModal(true);
                    }}
                    className=" pointer"
                  >
                    <div
                      style={{
                        color: 'black',
                        fontSize: '12px',
                        flexDirection: 'row',
                        display: 'flex',
                      }}
                    >
                      {shiftsData.rows[j]['Agencycandidate.profileimage'] ? (
                        <img
                          className="round-emp-image"
                          src={
                            apiConstants.imgUrl +
                            shiftsData.rows[j]['Agencycandidate.profileimage']
                          }
                        />
                      ) : (
                        <img
                          className=" round-emp-image"
                          src="/assets/img/recsy-fav-icon.png"
                        />
                      )}
                      {shiftsData.rows[j]['Agency.company_name'] &&
                      shiftsData.rows[j]['Agencycandidate.name']
                        ? `${
                            shiftsData.rows[j]['Agencycandidate.name'].length >
                            8
                              ? `${shiftsData.rows[j][
                                  'Agencycandidate.name'
                                ].substring(0, 8)}...`
                              : shiftsData.rows[j]['Agencycandidate.name']
                          }`
                        : ''}
                      <br />
                      {shiftsData.rows[j]['Agency.company_name'] &&
                      shiftsData.rows[j]['Agency.company_name'].length > 5
                        ? ` ( ${shiftsData.rows[j][
                            'Agency.company_name'
                          ].substring(0, 5)}...)`
                        : ` ( ${shiftsData.rows[j]['Agency.company_name']})`}
                    </div>
                  </div>
                )}
            </td>
          )}
          <td>
            {moment(shiftsData.rows[j].check_in_date_string).format(
              'DD-MMM-YYYY',
            )}
          </td>

          {/* {!props.isHistory && (
            <td>
              {moment(shiftsData.rows[j].check_out_date_string).format(
                'DD-MMM-YYYY',
              )}
            </td>
          )} */}
          <td>
            {/* <div style={{ fontWeight: 'bold', color: '#183245' }}>
              {shiftsData.rows[j]['Section.section_type']}
              <br />
              {'(' + shiftsData.rows[j]['Section.name'] + ')'}
            </div> */}
            <div style={{ fontSize: '10px' }}>
              {miscService.time24to12(shiftsData.rows[j].check_in_time_string)}{' '}
              -{' '}
              {miscService.time24to12(shiftsData.rows[j].check_out_time_string)}
            </div>
          </td>

          {!props.isHistory && (
            <td>
              {shiftsData.rows[j].attendance_check_in
                ? miscService.time24to12(shiftsData.rows[j].attendance_check_in)
                : '- -'}
            </td>
          )}

          {!props.isHistory && (
            <td>
              {shiftsData.rows[j].attendance_check_out
                ? miscService.time24to12(
                    shiftsData.rows[j].attendance_check_out,
                  )
                : '- -'}
            </td>
          )}
          <td>
            {shiftsData.rows[j].break_time
              ? shiftsData.rows[j].break_time + 'm'
              : '- -'}
          </td>
          <td>
            {shiftsData.rows[j].actual_hours_after_break_time
              ? shiftsData.rows[j].actual_hours_after_break_time
              : '- -'}
          </td>
          {(user_role == 1 || user_role == 6) && (
            <td>{'£' + shiftsData.rows[j].price} </td>
          )}
          {(user_role == 1 || user_role == 6) && (
            <td> {'£' + shiftsData.rows[j].employer_to_pay}</td>
          )}

          <td className="flx-shift-status">
            {shiftsData.rows[j].is_verified ? (
              <span
                className="shiftStatus"
                style={{ backgroundColor: 'rgba(194, 231, 204, 1)' }}
              >
                Completed
              </span>
            ) : (
              <>
                {currentTime < checkInTime ? (
                  <span
                    className="shiftStatus"
                    style={{ backgroundColor: 'rgba(196, 235, 248, 1)' }}
                  >
                    Upcoming
                  </span>
                ) : currentTime > checkOutTime ? (
                  <span
                    className="shiftStatus"
                    style={{ backgroundColor: 'rgba(255, 192, 192, 1)' }}
                  >
                    Absent
                  </span>
                ) : (
                  <span>- -</span>
                )}
              </>
            )}
          </td>

          {/* {shiftsData.rows[j].is_attended != -1 && (
            <td className="">
              {shiftsData.rows[j].is_verified ? (
                <span>Completed</span>
              ) : (
                <span>- -</span>
              )}
            </td>
          )} */}

          {/* {shiftsData.rows[j].is_attended == -1 && (
            <td className="absent">
              <span>{'Absent'}</span>
            </td>
          )}*/}
          <td style={{ textAlign: 'center' }}>
            {shiftsData.rows[j].invoice_id &&
            shiftsData.rows[j].invoice_generated == 1 ? (
              <i className="fa fa-check"></i>
            ) : (
              <i className="fa fa-times" style={{ color: '#DD3A3A' }}></i>
            )}
          </td>
          <td style={{ textTransform: 'capitalize' }}>
            {`${shiftsData.rows[j]['Employer.company_name']}`}
            {/* (${
              shiftsData.rows[j]['Businessunit.name'] ?? ''
            }${shiftsData.rows[j]['Employer.Parent.company_name'] ?? ''}`} */}
          </td>

          {!props.isHistory && (
            <td>
              {shiftsData &&
                shiftsData.rows &&
                shiftsData.rows[j].createdtime &&
                moment
                  .unix(shiftsData.rows[j].createdtime / 1000)
                  // .format('DD MMM YYYY hh:mm A')}
                  .format('DD MMM YYYY')}
            </td>
          )}
          {props.isHistory && (
            <td>
              <div
                className="allshift-viewdt flx-allshift-view"
                data-cy="viewshift"
                onClick={() => {
                  Setview_shift_id(shiftsData.rows[j]._id),
                    setviewdetailsmodal(true);
                }}
              >
                View
              </div>
            </td>
          )}

          {/* {!trashChecked &&
            // (isAdmin || isStaff) &&
            !cancelChecked &&
            !props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.is_report &&
            !props.isHistory && (
              <td
                className="eventCell event Edit"
                style={{ cursor: 'pointer' }}
              >
                {shiftsData.rows[j].status == 1 &&
                  !props.id &&
                  !props.employer_id &&
                  !props.agency_id && (
                    <div
                      onClick={() => {
                        deleteShiftModalShow(shiftsData.rows[j]._id);
                      }}
                    >
                      <img
                        data-cy="deleteshift"
                        src="/assets/img/delete.png"
                      ></img>
                    </div>
                  )}
              </td>
            )} */}

          {!trashChecked &&
            !props.isHistory &&
            !props.id &&
            !props.is_report &&
            !props.employer_id &&
            shiftsData.rows[j].status == 1 &&
            !props.agency_id && (
              <td
                className="eventCell event Edit"
                style={{ cursor: 'pointer' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // width: '100%',
                  }}
                >
                  {!shiftsData.rows[j]['Agencycandidate.name'] && (
                    <div
                      className="allshift-viewdt flx-allshift-edit"
                      data-cy="editshift"
                      onClick={() => {
                        editShift(shiftsData.rows[j]._id);
                      }}
                    >
                      <img
                        className=" "
                        src="/assets/img/edit-icon-pending-sign-off.svg"
                      />
                    </div>
                  )}

                  {shiftsData.rows[j].status == 1 && (
                    <div
                      data-cy="cancelshift"
                      style={{ color: '#DD3A3A', paddingRight: '10px' }}
                      onClick={() =>
                        cancelShiftModalShow(shiftsData.rows[j]._id)
                      }
                    >
                      Cancel
                    </div>
                  )}
                </div>
              </td>
            )}
          {(isAdmin || isStaff) &&
            trashChecked &&
            !props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.isHistory && (
              <td
                className="eventCell event Edit"
                style={{ cursor: 'pointer', color: 'rgb(58 116 221)' }}
              >
                {shiftsData.rows[j].status == 0 &&
                  !props.id &&
                  !props.employer_id &&
                  !props.agency_id &&
                  (isAdmin || isStaff) && (
                    <div
                      onClick={() => {
                        restoreShiftModalShow(shiftsData.rows[j]);
                      }}
                    >
                      Restore
                    </div>
                  )}
              </td>
            )}
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };
  // filter handler for sort order
  const handleSort = key => {
    setQuery(query => ({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order:
          query.pageVo.sort === key && query.pageVo.sort_order == 'asc'
            ? 'desc'
            : 'asc',
        sort: key,
      },
    }));
  };

  // select shift cancel reason handler
  const shiftcancelreasonChange = event => {
    const { value } = event.target;
    setcanceltype(value);
  };
  // to view trashed items
  const trash_checked = value => {
    setcancelChecked(false);
    settrashChecked(value);
    if (value) {
      setQuery({ ...query, status: 0 });
    } else {
      setQuery({ ...query, status: 1 });
    }
  };
  // to view cancel checked items
  // const cancelled_checked = value => {
  //   setcancelChecked(value);
  //   settrashChecked(false);
  //   if (value) {
  //     setQuery({ ...query, status: -1 });
  //   } else {
  //     setQuery({ ...query, status: 1 });
  //   }
  // };
  // custom styles for select
  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      background: '#f1f2f4',
      borderRadius: '12px',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000000',
    }),
  };

  const customStyles1 = {
    control: provided => ({
      ...provided,
      border: 'none',
      background: '#f1f2f4',
      borderRadius: '12px',
      padding: '0px 2px',
      width: '100%',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      fontWeight: '500',
      overflow: 'hidden',
      color: 'black',
      justifyContent: 'center',
    }),

    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),

    menuList: base => ({
      ...base,
      backgroundColor: '#ffffff',
      width: '200px',
      boxShadow: '0px 4px 19px 0px #00000014',
      borderRadius: '10px',
    }),
    multiValue: base => ({
      ...base,
      borderRadius: '12px',
      display: 'inline-flex',
      backgroundColor: 'none',
      alignItems: 'center',
      margin: '2px',
    }),
    multiValueLabel: base => ({
      ...base,
      fontSize: '10px',
      minWidth: '100px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: 'none',
    }),
    multiValueRemove: base => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'flex-start',
    }),
    dropdownIndicator: base => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px 4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#ffffff'
        : // : state.isFocused
          // ? '#ffffff'
          '#ffffff',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000000',
    }),
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionChange = selectedOptions => {
    setSelectedOptions(selectedOptions);

    const isCancelledSelected = selectedOptions.some(
      option => option.value === 'option1',
    );

    const isActiveSelected = selectedOptions.some(
      option => option.value === 'option2',
    );

    if (isCancelledSelected && isActiveSelected) {
      setQuery({ ...query, status: [1, -1] });
    } else if (isCancelledSelected) {
      setQuery({ ...query, status: -1 });
    } else if (isActiveSelected) {
      setQuery({ ...query, status: 1 });
    } else {
      setQuery({ ...query, status: null });
    }

    cancelled_checked(isCancelledSelected);
  };
  const cancelled_checked = value => {
    setcancelChecked(value);
    settrashChecked(false);
  };

  const getShiftStatus = async inputValue => {
    return [
      { value: 1, label: 'Completed' },
      { value: 2, label: 'In Progress' },
      { value: 3, label: 'Absent' },
      { value: 4, label: 'Not started' },
      { value: 5, label: 'Upcoming' },
    ].filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const getActiveStatus = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        const options = [
          { value: 'option2', label: 'Active' },
          { value: 'option1', label: 'Cancelled' },
        ];
        resolve(options);
      }, 1000);
    });
  };

  const getSignedOffStatus = [
    {
      value: 1,
      label: 'YES',
    },
    {
      value: 2,
      label: 'NO',
    },
  ];

  //signed off filter for async
  const handleSignedOff = selectedOption => {
    const selectedValues = selectedOption
      ? selectedOption?.map(option => option.value)
      : [];

    setQuery(prevState => ({
      ...prevState,
      employer_signature_url: selectedValues,
    }));
  };

  // handle status change
  const status_change = selectedOptions => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : [];

    setQuery(prevState => ({
      ...prevState,
      shift_status: selectedValues,
    }));
  };

  // get checkbox
  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label style={{ paddingLeft: '5px' }}>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  // get all shifts ids if select matching filter
  const handleTotalShifts = () => {
    setSelectAllShifts(true);
    setSelectAllClicked(false);

    fetch(apiConstants.apiUrl + '/getshiftsList', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        pageVo: {
          pageNo: 1,
          noOfItems: shiftsData.total,
        },
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          setSelectedShiftsId(data.rows);

          const allShiftIds = data.rows.map(shift => shift._id);
          setAllSelectedShiftIds(allShiftIds);
          setallselectedpages(allShiftIds);
        }),
      )
      .catch(error => {
        console.error(error);
      });
  };

  // handle clear selection
  const handleClearSelection = () => {
    setSelectAllShifts(false);
    checkAllHandle(false);
  };
  const callBackBusinessunits = () => {
    getAllShifts();
  };
  return (
    <div className="ShiftList">
      <div className="col-md-12">
        <div
          className=" regular-table-with-color"
          style={
            props.isHistory ? { marginLeft: '0px', marginRight: '0px' } : {}
          }
        >
          <div className=" page-header ">
            {/* {!props.isHistory && (
              <div className="row mt-20">
                <div className="col-md-9">
                  <div className="page-title"> Shift list</div>
                </div>
              </div>
            )} */}
            {!props.id &&
              !props.employer_id &&
              !props.agency_id &&
              !props.employer_id &&
              !props.isHistory &&
              !props.businessunit &&
              !props.is_report &&
              !props.category &&
              !props.organization_id && (
                <div className="filter-row mt-1">
                  <div className=" filtero ">
                    <div className="filter-row">
                      <div className="filter-item">
                        <div
                          className={styles.dateInputWrapper}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type="date"
                            name="from_date"
                            value={query.from_date}
                            onChange={handleChange}
                            onFocus={handleFromDateFocus}
                            onBlur={handleFromDateBlur}
                            // className={`${styles.boldPlaceholder} ${
                            //   query.from_date ? '' : styles.emptyDate
                            // } `}
                            data-cy="shift-date"
                            style={{
                              marginTop: '20px',
                              height: '38px',
                              width: '100%',
                              border: 'none',
                              background: '#F1F2F4',
                              position: 'relative',
                              borderRadius: '12px',
                              fontSize: '12px',
                              padding: '4px 10px',
                              marginRight: '10px',
                              color:
                                query.from_date || isFromDateFocused
                                  ? 'inherit'
                                  : 'transparent',
                            }}
                          />
                          {!query.from_date && !isFromDateFocused && (
                            <label
                              className={styles.datePlaceholder}
                              htmlFor="from_date"
                            >
                              Shifts From
                            </label>
                          )}
                        </div>
                      </div>

                      {/* <div className="filter-item">
                        <div
                          className={styles.dateInputWrapper}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type="date"
                            name="from_date"
                            value={query.from_date}
                            onChange={handleChange}
                            onFocus={handleFromDateFocus}
                            onBlur={handleFromDateBlur}
                            className={`${styles.boldPlaceholder} ${
                              query.from_date ? '' : styles.emptyDate
                            } `}
                            data-cy="shift-date"
                            style={{
                              marginTop: '20px',
                              height: '38px',
                              width: '100%',
                              border: 'none',
                              background: '#F1F2F4',
                              position: 'relative',
                              borderRadius: '12px',
                              fontSize: '12px',
                              padding: '4px 10px',
                              marginRight: '10px',
                              color:
                                query.from_date || isFromDateFocused
                                  ? 'inherit'
                                  : 'transparent',
                            }}
                          />
                          {!query.from_date && !isFromDateFocused && (
                            <label
                              className={styles.datePlaceholder}
                              htmlFor="from_date"
                            >
                              Shifts From
                            </label>
                          )}
                        </div>
                      </div> */}

                      <div className="filter-item">
                        <div
                          className={styles.dateInputWrapper}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type="date"
                            name="to_date"
                            value={query.to_date}
                            onChange={handleChange}
                            onFocus={handleToDateFocus}
                            onBlur={handleToDateBlur}
                            // className={`${styles.boldPlaceholder} ${
                            //   query.from_date ? '' : styles.emptyDate
                            // } `}
                            data-cy="shift-date"
                            style={{
                              marginTop: '20px',
                              height: '38px',
                              width: '100%',
                              border: 'none',
                              background: '#F1F2F4',
                              position: 'relative',
                              borderRadius: '12px',
                              fontSize: '12px',
                              padding: '4px 10px',
                              marginRight: '10px',
                              color:
                                query.to_date || isToDateFocused
                                  ? 'inherit'
                                  : 'transparent',
                            }}
                          />
                          {!query.to_date && !isToDateFocused && (
                            <label
                              className={styles.datePlaceholder}
                              htmlFor="to_date"
                            >
                              Shifts To
                            </label>
                          )}
                        </div>
                      </div>

                      {/* <div className="filter-item">
                        <div
                          className={styles.dateInputWrapper}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type="date"
                            name="to_date"
                            value={query.to_date}
                            onChange={handleChange}
                            onFocus={handleToDateFocus}
                            onBlur={handleToDateBlur}
                            className={`${styles.boldPlaceholder} ${
                              query.to_date ? '' : styles.emptyDate
                            }`}
                            data-cy="shift-date"
                            style={{
                              marginTop: '20px',
                              height: '38px',
                              width: '100%',
                              border: 'none',
                              background: '#F1F2F4',
                              position: 'relative',
                              borderRadius: '12px',
                              fontSize: '12px',
                              padding: '4px 10px',
                              marginRight: '10px',
                              color:
                                query.to_date || isToDateFocused
                                  ? 'inherit'
                                  : 'transparent',
                            }}
                          />
                          {!query.to_date && !isToDateFocused && (
                            <label
                              className={styles.datePlaceholder}
                              htmlFor="to_date"
                            >
                              Shifts To
                            </label>
                          )}
                        </div>
                      </div> */}

                      {/* -------- Employers filter ---------- */}
                      {(user_role == 1 || user_role == 6) && (
                        <div className="filter-item filter-item9">
                          <AsyncSelect
                            styles={customStyles1}
                            name="employer"
                            placeholder="Employer"
                            cacheOptions
                            defaultOptions={
                              selectedBusinessUnit.length > 0
                                ? filteredEmployers
                                : allEmployers
                            }
                            loadOptions={getEmployers}
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => 'No results found'}
                            isMulti
                            components={{
                              Option,
                            }}
                            value={selectedEmployer}
                            // inputValue={inputValue}
                            onChange={setEmployer}
                            hideSelectedOptions={false}
                          />
                        </div>
                      )}

                      {/* --------- Business Unit filter --------- */}
                      {(user_role == 1 || user_role == 6) && (
                        <div className="filter-item filter-item9">
                          <AsyncSelect
                            styles={customStyles1}
                            name="business_unit"
                            placeholder="Business Unit"
                            cacheOptions
                            defaultOptions={
                              selectedEmployer.length > 0
                                ? businessUnitFilterRows
                                : allBusinessUnits
                            }
                            loadOptions={getBusinessUnits}
                            onChange={handleBusinessUnits}
                            isMulti={true}
                            noOptionsMessage={() => 'No results found'}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            components={{ Option }}
                            value={selectedBusinessUnit}
                          />
                        </div>
                      )}

                      {/* ------- Job Role filter --------- */}

                      {(user_role == 1 || user_role == 6) && (
                        <div className="filter-item">
                          <div>
                            <label className="ml-10" htmlFor="type"></label>
                          </div>
                          <AsyncSelect
                            styles={customStyles1}
                            name="category"
                            placeholder="Job Role"
                            cacheOptions
                            defaultOptions
                            loadOptions={getCategorylist}
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => 'No results found'}
                            isMulti
                            components={{
                              Option,
                            }}
                            value={selectedCategories}
                            onChange={category_change}
                            hideSelectedOptions={false}
                          />
                        </div>
                      )}

                      {/* --------  Status filter ---------- */}

                      {(user_role == 1 || user_role == 6) && (
                        <div className="filter-item filter-item9">
                          <AsyncSelect
                            styles={customStyles1}
                            cacheOptions
                            defaultOptions
                            loadOptions={getShiftStatus}
                            onChange={status_change}
                            placeholder="Status"
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            components={{
                              Option,
                            }}
                            noOptionsMessage={() => 'No results found'}
                            isMulti={true}
                            // className="col-md-12 selectdesing2 mt-3 item-2"
                          />
                        </div>
                      )}

                      {/* ----------- Signed Off filter ----------- */}
                      {(user_role == 1 || user_role == 6) && (
                        <div className="filter-item filter-item9">
                          <AsyncSelect
                            styles={customStyles1}
                            cacheOptions
                            defaultOptions={getSignedOffStatus}
                            onChange={handleSignedOff}
                            placeholder="Signed Off"
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            components={{
                              Option,
                            }}
                            noOptionsMessage={() => 'No results found'}
                            isMulti={true}
                          />
                        </div>
                      )}

                      {(user_role == 1 || user_role == 6) && (
                        <div className="filter-item filter-item9">
                          <AsyncSelect
                            styles={customStyles1}
                            value={selectedOptions}
                            cacheOptions
                            defaultOptions
                            loadOptions={getActiveStatus}
                            onChange={handleOptionChange}
                            placeholder="Active"
                            hideSelectedOptions={false}
                            closeMenuOnSelect={true}
                            components={{
                              Option,
                            }}
                            noOptionsMessage={() => 'No results found'}
                            isMulti={true}
                          />
                        </div>
                      )}

                      {user_role == 4 && (
                        <div className="filter-item">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type"></label>
                          </div>
                          <AsyncSelect
                            styles={customStyles}
                            name="candidate_id"
                            onChange={selectedcandidate}
                            placeholder={'select candidate'}
                            cacheOptions
                            defaultOptions
                            noOptionsMessage={() => 'No results found'}
                            loadOptions={getcandidatelist}
                            // className="candidateselectt "
                          />
                        </div>
                      )}
                      {user_role != 1 && user_role != 6 && user_role != 4 && (
                        <div className="filter-item">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type"></label>
                          </div>
                          <AsyncSelect
                            styles={customStyles}
                            name="candidate_id"
                            onChange={selectedallocatecandidate}
                            placeholder={'select candidate'}
                            cacheOptions
                            defaultOptions
                            className="allocatedcandiadte"
                            noOptionsMessage={() => 'No results found'}
                            loadOptions={getshiftscandidatelist}
                          />
                        </div>
                      )}

                      {user_role != 1 && user_role != 6 && (
                        <div className="filter-item ">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type"></label>
                          </div>
                          <AsyncSelect
                            styles={customStyles}
                            name="agency_id"
                            onChange={agency_change}
                            placeholder="select agency"
                            cacheOptions
                            defaultOptions
                            // className="agencyselectt"
                            // isMulti
                            noOptionsMessage={() => 'No results found'}
                            loadOptions={getAgencylist}
                          />
                        </div>
                      )}
                      {/* TO DO : Temporary removal of Verified Filter */}

                      {id != UNVERIFIED && user_role != 1 && user_role != 6 && (
                        <div className=" ">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type"></label>
                          </div>
                          <select
                            className="select selectDesign selectDesign1 "
                            data-cy="isverifyy"
                            name="is_verified"
                            value={query.is_verified}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Verified
                            </option>
                            <option value={''}> ALL</option>
                            <option value={1}>Verified</option>
                            {user_role != 1 && (
                              <option value={0}>Not verified</option>
                            )}
                            {(user_role == 1 || user_role == 6) && (
                              <option value={0}>To verify</option>
                            )}
                          </select>
                        </div>
                      )}
                    </div>
                    {/*hiding it temporarily 
                    <div className=" button-row  ">
                      <div className="">
                        <div
                          className="c btn btn-primary-blue  mt-1"
                          data-cy="assign_shift"
                          onClick={() => {
                            setShowshiftAddmodal(true);
                          }}
                        >
                          + Assign Shift
                        </div>
                      </div>
                      <div className="">
                        <div
                          data-cy="Add_timesheet"
                          className="attendance-div card-body2 mt-1 p-2 "
                          onClick={() => {
                            setTimesheetModal(true);
                          }}
                        >
                          <p className="f-10 fontwghtbold"> Add Timesheet</p>
                        </div>
                      </div>
                    </div>
                        */}
                    {/* Menu items assign shift and download button */}
                    <div className="d-flex align-items-start mt-4">
                      <div
                        className={
                          'flx-shifts-download-dropdown ' +
                          (Object.keys(selectedShifts).length
                            ? 'btn-active'
                            : 'btn-disabled')
                        }
                        ref={dropdownRef}
                      >
                        <i className="flx-assign-shift-download-icon fa fa-download"></i>{' '}
                        <button
                          className="btn btn-secondary flx-shifts-download-dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          onClick={toggleDropdown}
                          aria-haspopup="true"
                          // aria-expanded={isDropdownOpen}
                          aria-expanded="false"
                        >
                          Download
                        </button>
                        {isDropdownOpen && (
                          <div className="flx-shifts-download-dropdown-menu">
                            <CsvDownload
                              filename={`shifts-${miscService.getUTCTime(
                                new Date(),
                              )}.csv`}
                              disabled={
                                !Object.keys(selectedShifts).length &&
                                !allSelectedShiftIds.length
                              }
                              data={
                                selectAllShifts
                                  ? shiftsService.getShiftsCsvData(
                                      selectedShiftsId,
                                    )
                                  : shiftsService.getShiftsCsvData(
                                      selectedShifts,
                                    )
                              }
                              style={{ cursor: 'default' }}
                              delimiter=","
                            >
                              <div
                                className={
                                  'flx-shifts-download-dropdown-item ' +
                                  (Object.keys(selectedShifts).length ||
                                  selectedShiftsId.length
                                    ? 'btn-active'
                                    : 'btn-disabled')
                                }
                                disabled={
                                  !Object.keys(selectedShifts).length &&
                                  !selectedShiftsId.length
                                }
                              >
                                <i className="fas fa-file-csv"></i> CSV
                              </div>
                            </CsvDownload>

                            <a
                              className={
                                'flx-shifts-download-dropdown-item ' +
                                (Object.keys(selectedShifts).length
                                  ? 'btn-active'
                                  : 'btn-disabled')
                              }
                              style={{ paddingLeft: '10px' }}
                              disabled={
                                !Object.keys(selectedShifts).length &&
                                !allSelectedShiftIds.length
                              }
                              data-cy="printshifts"
                              href={
                                Object.keys(selectedShifts).length ||
                                allSelectedShiftIds.length
                                  ? `/shiftprint/0/${
                                      selectAllShifts
                                        ? allSelectedShiftIds
                                        : Object.keys(selectedShifts)
                                    }/0/0/0`
                                  : '#'
                              }
                              target="blank"
                              rel="noreferrer"
                            >
                              <i className="fas fa-file-pdf"></i> PDF
                            </a>
                          </div>
                        )}
                      </div>
                      <i className="flx-assign-shift-user-icon fa fa-user-plus ml-2"></i>
                      <button
                        className="flx-shift-assign-shift"
                        data-cy="assign_shift"
                        onClick={() => {
                          setShowshiftAddmodal(true);
                        }}
                      >
                        Assign Shift
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {/* {!props.isHistory && (
              <div className="">
                {query.from_date && (
                  <label>
                    Shift from{' '}
                    {moment(query.from_date, 'yyyy-MM-DD').format(
                      'DD-MMM-yyyy',
                    )}
                  </label>
                )}
              </div>
            )} */}
          </div>
          {!props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.employer_id &&
            !props.isHistory &&
            !props.businessunit &&
            !props.is_report &&
            !props.category &&
            !props.organization_id && (
              <div className="card-header page-header pl-0 pr-0 ">
                <div className="d-flex flex-1 align-items-center">
                  <div className="filter-row">
                    {/* TODO : removal of attendance */}
                    {user_role != 1 && user_role != 6 && (
                      <div className="filter-item ">
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type"></label>
                        </div>
                        <select
                          className="select col-md-12 selectDesign39 selectDesign1"
                          name="is_attended"
                          value={query.is_attended}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            Select attendance
                          </option>
                          <option value={''}> ALL</option>
                          <option value={1}>Attended</option>
                          <option value={-1}> Absent</option>
                        </select>
                      </div>
                    )}

                    {user_role != 1 && user_role != 6 && (
                      <div className="filter-item ">
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type"></label>
                        </div>
                        <select
                          className="select col-md-12 selectDesign39 selectDesign1"
                          name="is_verified"
                          data-cy="timesheet"
                          value={query.is_verified}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            Timesheet
                          </option>
                          <option value={''}> ALL</option>
                          <option value={1}> timesheet generated</option>
                          <option value={0}> Not timesheet generated</option>
                        </select>
                      </div>
                    )}

                    {user_role != 1 && user_role != 6 && (
                      <div className="filter-item ">
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type"></label>
                        </div>
                        <select
                          className="select col-md-12 selectDesign39 selectDesign1"
                          name="invoice_generated"
                          value={query.invoice_generated}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            Invoice
                          </option>
                          <option value={''}> ALL</option>
                          <option value={1}> invoice generated</option>
                          <option value={0}> Not invoice generated</option>
                        </select>
                      </div>
                    )}

                    {user_role != 1 && user_role != 6 && (
                      <div className="filter-item ">
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type"></label>
                        </div>
                        <select
                          className="select col-md-12 selectdesing2 selectDesign1"
                          name="branch"
                          value={query.branch}
                          onChange={handleChange}
                        >
                          <option value="77" hidden={true}>
                            {' '}
                            Select Branch
                          </option>
                          <option value=""> ALL</option>
                          <option value={'Basildon'}>Basildon</option>
                          <option value={'Wales'}>Wales</option>
                        </select>
                      </div>
                    )}

                    {/* //------------ parent child  filter ---------------- */}
                    {isParentEmployer && (
                      <div className="filter-item ">
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type"></label>
                        </div>
                        <select
                          className="select col-md-12 selectdesing2 selectDesign1 "
                          name="organization_id"
                          value={query.organization_id}
                          onChange={handleChange}
                        >
                          <option value="null"> ALL</option>
                          <option value="" disabled selected>
                            Organization
                          </option>
                          {organizationRows}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="download-items hidden-filters">
                    <a
                      className={
                        'btn-primary btn btn-downlaodpdf ' +
                        (Object.keys(selectedShifts).length
                          ? 'btn-active'
                          : 'btn-disabled')
                      }
                      disabled={!Object.keys(selectedShifts).length}
                      data-cy="printshifts"
                      href={
                        Object.keys(selectedShifts).length
                          ? `/shiftprint/0/${Object.keys(selectedShifts)}/0/0/0`
                          : '#'
                      }
                      target="blank"
                      rel="noreferrer"
                    >
                      <span
                        className="button-icon"
                        style={{
                          backgroundImage: `url(/assets/img/black-download.png)`,
                        }}
                      ></span>{' '}
                      Download Pdf
                    </a>

                    <CsvDownload
                      filename={`shifts-${miscService.getUTCTime(
                        new Date(),
                      )}.csv`}
                      disabled={!Object.keys(selectedShifts).length}
                      data={shiftsService.getShiftsCsvData(selectedShifts)}
                      style={{ cursor: 'default' }}
                      delimiter=","
                    >
                      <div
                        className={
                          'btn-primary btn btn-downlaodpdf ' +
                          (Object.keys(selectedShifts).length
                            ? 'btn-active'
                            : 'btn-disabled')
                        }
                        disabled={!Object.keys(selectedShifts).length}
                      >
                        <span
                          className="button-icon"
                          style={{
                            backgroundImage: `url(/assets/img/black-download.png)`,
                          }}
                        ></span>{' '}
                        Download CSV
                      </div>
                    </CsvDownload>
                  </div>

                  {/* 
                  <div className="">
                    <div
                      className={
                        'downloadbtn-div card-body2 mt-1 p-2 ' +
                          Object.keys(selectedShifts).length >
                        0
                          ? 'btn-disabled'
                          : 'btn-active'
                      }
                    >
                      <a
                        data-cy="printshifts"
                        href={`/shiftprint/0/${Object.keys(
                          selectedShifts,
                        )}/0/0/0`}
                        target="blank"
                      >
                        <p className="f-12 fontwghtbold"> print shifts </p>
                      </a>
                      <img
                        style={{
                          height: 17,
                          width: 17,
                          marginLeft: '5px',
                        }}
                        src="/assets/img/black-download.png"
                      />
                    </div>
                  </div>

                  <div
                    className={
                      'btnDownloadCsv ' + Object.keys(selectedShifts).length > 0
                        ? 'btn-disabled'
                        : 'btn-active'
                    }
                  >
                    <CsvDownload
                      style={{
                        border: 'none',
                        padding: '4px',
                        backgroundColor: '#f7f9fb',
                      }}
                      data={shiftsService.getShiftsCsvData(selectedShifts)}
                    >
                      Download CSV
                    </CsvDownload>
                    <div>
                      <img
                        style={{ height: 15, width: 15 }}
                        src="/assets/img/csv1.png"
                      />
                    </div>
                  </div> */}

                  {!isBunit && user_role != 1 && user_role != 6 && (
                    <div className="button-row  ">
                      <div
                        data-cy="Trash"
                        className={
                          trashChecked
                            ? 'trashButtonActive'
                            : 'trashButtonInActive'
                        }
                        onClick={() => {
                          trash_checked(!trashChecked);
                        }}
                      >
                        Trash
                      </div>
                      <div
                        data-cy="Cancelled"
                        className={
                          cancelChecked
                            ? 'trashButtonActive ml-2'
                            : 'trashButtonInActive ml-2'
                        }
                        onClick={() => {
                          cancelled_checked(!cancelChecked);
                        }}
                      >
                        Cancelled
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          {/* {!props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.employer_id &&
            !props.isHistory &&
            !props.businessunit &&
            !props.is_report &&
            !props.category &&
            !props.organization_id && (
              <div className="d-flex">
                <div className="filter-item fontwghtbold">
                  Shifts Selected : {Object.keys(selectedShifts).length}
                </div>
                <div className="fontwghtbold">
                  Fully Selected Pages : {''}
                  {allselectedpages.join(', ')}
                </div>
              </div>
            )} */}
          <div className="second-row">
            <div className="second-row-left">
              {!props.id &&
                !props.employer_id &&
                !props.agency_id &&
                !props.isHistory &&
                !props.businessunit &&
                !props.category &&
                !props.organization_id && (
                  <div>
                    <span
                      data-cy="selectshift"
                      onClick={() => {
                        allselectedpages.includes(query.pageVo.pageNo)
                          ? checkAllHandle(false)
                          : checkAllHandle(true);
                      }}
                    >
                      {allselectedpages.includes(query.pageVo.pageNo) ? (
                        <img
                          className="check_style"
                          src="/assets/img/check1.png"
                        />
                      ) : (
                        <img
                          className="check_style"
                          src="/assets/img/un_check1.png"
                        />
                      )}
                    </span>
                    <span className="shift-sign-off-form-check form-check-label  select-all-label ">
                      Select All
                    </span>
                  </div>
                )}
            </div>
            {selectAllClicked && (
              <div className="">
                <span>
                  All {Object.keys(selectedShifts).length} shift on this page
                  are selected.
                </span>
                <span className="select-all-data" onClick={handleTotalShifts}>
                  Select all {shiftsData.total} shift in the matching filter
                </span>
              </div>
            )}

            {selectAllShifts && (
              <div>
                <span>
                  All {shiftsData.total} shift in the matching filter are
                  selected.
                </span>
                <span
                  className="select-all-data"
                  onClick={handleClearSelection}
                >
                  Clear selection
                </span>
              </div>
            )}

            <div className="pagination-top">
              <PaginationNew
                pageOnClick={data => {
                  getAllShifts(data);
                }}
                paginationData={shiftsData}
              />
            </div>
          </div>
          <div
            className="  table-responsive main-card-body scrollable-table-container"
            style={{ paddingTop: 0 }}
          >
            {!isLoading && (
              <table className="table table-hover flx-custom-table">
                <thead>
                  <tr>
                    {!props.id &&
                      !props.employer_id &&
                      !props.agency_id &&
                      !props.isHistory &&
                      !props.businessunit &&
                      !props.category &&
                      !props.organization_id && (
                        <th className="border-top1">
                          {/* <div
                            data-cy="selectshift"
                            onClick={() => {
                              allselectedpages.includes(query.pageVo.pageNo)
                                ? checkAllHandle(false)
                                : checkAllHandle(true);
                            }}
                          >
                            {allselectedpages.includes(query.pageVo.pageNo) ? (
                              <img
                                className="check_style"
                                src="/assets/img/check1.png"
                              />
                            ) : (
                              <img
                                className="check_style"
                                src="/assets/img/un_check1.png"
                              />
                            )}
                          </div> */}
                        </th>
                      )}
                    {!props.isHistory && (
                      <th
                        onClick={() => handleSort('candidate_name')}
                        className="sortHead"
                      >
                        Name{' '}
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'candidate_name' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'candidate_name' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                    )}
                    <th
                      onClick={() => handleSort('check_in_date_string')}
                      className="sortHead1"
                    >
                      From
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'check_in_date_string' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'check_in_date_string' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    {/* {!props.isHistory && (
                      <th
                        onClick={() => handleSort('check_out_date_string')}
                        className="sortHead"
                      >
                        To
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'check_out_date_string' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'check_out_date_string' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                    )} */}
                    <th className="sortHead1">Shift</th>
                    {!props.isHistory && <th>Check in</th>}
                    {!props.isHistory && <th>Check out</th>}
                    <th>Break </th>
                    <th>Hours</th>
                    {(user_role == 1 || user_role == 6) && <th>Price</th>}
                    {(user_role == 1 || user_role == 6) && (
                      <th>Employer pay</th>
                    )}
                    {/* <th>Timesheet</th> */}
                    <th>Status</th>
                    <th>Invoice generated</th>
                    <th
                      onClick={() => handleSort('employer_name')}
                      className="employerHead"
                    >
                      Employer
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'employer_name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'employer_name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    {!props.isHistory && (
                      <th
                        onClick={() => handleSort('createdtime')}
                        className="sortHead1"
                      >
                        Created
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'createdtime' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'createdtime' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                    )}
                    {!trashChecked &&
                      !cancelChecked &&
                      !props.id &&
                      !props.is_report &&
                      !props.employer_id &&
                      !props.agency_id &&
                      // (isAdmin || isStaff) &&
                      !props.isHistory && <th></th>}
                    {/* {!trashChecked &&
                      !cancelChecked &&
                      !props.id &&
                      !props.is_report &&
                      !props.employer_id &&
                      !props.agency_id &&
                      // (isAdmin || isStaff) &&
                      !props.isHistory && <th className="sortHead"></th>} */}
                    {/* delete  */}
                    {/* {!trashChecked &&
                      !cancelChecked &&
                      !props.id &&
                      !props.is_report &&
                      !props.employer_id &&
                      !props.agency_id &&
                      (isAdmin || isStaff) &&
                      !props.isHistory && <th className="sortHead"></th>} */}
                    {trashChecked &&
                      !cancelChecked &&
                      !props.id &&
                      !props.is_report &&
                      !props.employer_id &&
                      !props.agency_id &&
                      (isAdmin || isStaff) &&
                      !props.isHistory && <th></th>}
                    {/* {!props.isHistory &&
                      !(isAdmin || isStaff) &&
                      !props.is_report &&
                      !trashChecked &&
                      !cancelChecked && <th className="sortHead"></th>} */}
                  </tr>
                </thead>
                <tbody>{shiftsRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingImg />}
            {shiftsRows && shiftsRows.length === 0 && !isLoading && (
              <p className="margin-top-20 text-center"> No result found </p>
            )}
          </div>
          {/* <div className="card-footer hidescroll center-white">
            <Pagination
              pageOnClick={data => {
                getAllShifts(data);
              }}
              paginationData={shiftsData}
            />
          </div> */}
        </div>
      </div>
      {viewdetailsmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-70 ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close-btn-viewDetails ">
                    <a
                      data-cy="closemodal"
                      onClick={() => setviewdetailsmodal(false)}
                    >
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <Shifts id={view_shift_id} />
              </div>
            </div>
          </div>
        </div>
      )}
      {shiftverificationmodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className=" modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshiftverificationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Confirm Verification ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshiftverificationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() => confirmshiftverification()}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showshiftAddmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setShowshiftAddmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <ShiftsAdd
                  callBackBusinessunits={callBackBusinessunits}
                  allocateshift={false}
                  isSelectUsershow={true}
                  showAddshiftmodal={showAddshiftmodal}
                  isMultipleDays={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* //----------edit shift modal -------------------/ */}
      {showshiftEditmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setShowshiftEditmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <ShiftEdit
                  isSelectUsershow={true}
                  showEditshiftmodal={showEditshiftmodal}
                  _id={editShiftId}
                  isMultipleDays={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setDeleteConfirmationModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <div className="d-flex justify-content-center">
                        <img
                          className="delete-icon"
                          src="/assets/img/bin.png"
                        ></img>
                      </div>
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure you want to delete shift?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setDeleteConfirmationModal(false);
                          }}
                        >
                          Close
                        </button>

                        <button
                          className="button_delete_close width_100"
                          data-cy="deletebutton"
                          onClick={() => deleteshift()}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {cancelconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setCancelConfirmationModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body ml-30">
                <div className="bag">
                  <div className="row d-flex ">
                    <div className="col-md-12 d-flex flex-column ml-20">
                      <div className="d-flex mb-10">
                        <div className="p-2">
                          <img
                            style={{ height: '30px', width: '30px' }}
                            className="delete-icon"
                            src="/assets/img/warning.png"
                          ></img>
                        </div>
                        <div
                          className="align-items-center"
                          style={{ lineHeight: '23px', padding: '10px' }}
                        >
                          <h4 className="font-weight-bold">Cancel Shift</h4>
                          <p style={{ color: '#a2a2a2' }}>
                            Are you sure you want cancel the shift? this shift
                            wont be considered anymore and this action cannot be
                            undone
                          </p>
                        </div>
                      </div>
                      {/* (isAdmin || isStaff) &&  if I remove it then employer can cancel it. */}
                      {
                        <div className="mb-30">
                          <small>* Select reason for cancellation </small>
                          <select
                            className="select col-md-10 selectDesign"
                            name="cancel_type"
                            value={query.cancel_type}
                            onChange={shiftcancelreasonChange}
                          >
                            <option value={''}>Select</option>
                            <option value={'Accidentally added shift'}>
                              Accidentally added shift{' '}
                            </option>
                            <option value={'Emergency cancellation'}>
                              Emergency cancellation
                            </option>
                            <option
                              value={'Shift cannot be attended on this date'}
                            >
                              Shift cannot be attended on this date
                            </option>
                            <option value="Other"> Other</option>
                          </select>
                        </div>
                      }
                      {!canceltype && submitted && (
                        <div className="help-block">
                          Cancel type is required
                        </div>
                      )}
                      <div className="mb-30 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center ">
                          <button
                            style={{ margin: '0px' }}
                            className="button_delete width_100"
                            onClick={() => {
                              setCancelConfirmationModal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="button_delete_close width_100"
                            data-cy="cancelshiftbutton"
                            onClick={() => cancelshift()}
                          >
                            Ok
                            {isCancelLoading && (
                              <div className="lds-ring ml-2">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {restoreconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setRestoreConfirmationModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body ml-30">
                <div className="bag">
                  <div className="row d-flex ">
                    <div className="col-md-12 d-flex flex-column ml-20">
                      <div className="d-flex mb-10">
                        <div className="p-2">
                          <img
                            style={{ height: '40px', width: '40px' }}
                            className="delete-icon"
                            src="/assets/img/restoreicon.png"
                          ></img>
                          {/* <FontAwesomeIcon icon="fas fa-sync-alt" /> */}
                        </div>
                        <div
                          className="align-items-center"
                          style={{ lineHeight: '23px', padding: '10px' }}
                        >
                          <h4 className="font-weight-bold">Restore Shift</h4>
                          <p style={{ color: '#a2a2a2' }}>
                            Are you sure you want to restore the shift?
                          </p>
                        </div>
                      </div>
                      <div className="mb-30 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center ">
                          <button
                            style={{ margin: '0px' }}
                            className="button_delete width_100"
                            onClick={() => {
                              setRestoreConfirmationModal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="button_delete_restore width_100"
                            onClick={() => restoreshift()}
                          >
                            Restore
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <CandidateProfile
                  candidate_id={SelectedCandidateId}
                ></CandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAgencyCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowAgencyCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <AgencyCandidateProfile
                  agency_candidate_id={SelectedCandidateId}
                ></AgencyCandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
      {showInvoicemodal && (
        <div
          className="modal"
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-large ">
            <div className="modal-content ">
              <div className="modal-body">
                <div className="bag">
                  <InvoiceAdd
                    showInvoiceModal={showInvoiceModalfunction}
                    shifts_Ids={Object.keys(selectedShifts)}
                  ></InvoiceAdd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPayoutmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-large ">
            <div className="modal-content ">
              <div className="modal-body">
                <div className="bag">
                  <PayoutAdd2
                    showPayoutModal={showPayoutModalfunction}
                    shifts_Ids={Object.keys(selectedShifts)}
                    csvDownloadBulk={true}
                  ></PayoutAdd2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {timesheetmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-medium">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setTimesheetModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              {Object.keys(selectedShifts).length > 0 ? (
                <div className="modal-body">
                  <div className="bag">
                    <AttendaceBloackbooking
                      hidefilter={1}
                      showTimesheetModal={showTimesheetModalfunction}
                      shifts_Ids={Object.keys(selectedShifts)}
                    ></AttendaceBloackbooking>
                  </div>
                </div>
              ) : (
                <div className="modal-body">
                  <div className="bag">
                    <AttendaceBloackbooking
                      showTimesheetModal={showTimesheetModalfunction}
                    ></AttendaceBloackbooking>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// loader for candidate list in async select
const getcandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          // Check if there are any data results
          if (data && data.rows && data.rows.length > 0) {
            let AllElement = { _id: 0, name: 'ALL' };
            let newArray = [AllElement, ...data.rows];
            resolve(
              newArray.map(item => ({
                value: item['_id'],
                label: `${item['name']} ${item['last_name'] ?? ''}`,
              })),
            );
          } else {
            // No options found
            resolve([]);
          }
        }),
      )
      .catch(reject);
  });
// loader for shift candidate list  in async select
const getshiftscandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    let employer_id;
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      employer_id = user.employer_id;
    }
    fetch(apiConstants.apiUrl + '/getshiftscandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        employer_id: employer_id,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          // add a default all value in filter option
          if (data && data.rows && data.rows.length > 0) {
            let AllElement = { _id: 0, name: 'ALL' };
            let newArray = [AllElement].concat(data.rows);
            resolve(
              newArray.map(i => ({
                value: i['_id'],
                label: `${i['name']} ${i['last_name'] ?? ''}`,
              })),
            );
          } else {
            // No options found
            resolve([]);
          }
        }),
      )
      .catch(reject);
  });
// loader for category in async select
const getCategorylist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategorylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        isCategories: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          if (data && data.rows && data.rows.length > 0) {
            // let AllElement = { _id: 0, name: 'ALL' };
            // let newArray = [AllElement, ...data.rows];
            resolve(
              data.rows.map(({ _id, name }) => ({
                value: _id,
                label: name,
              })),
            );
          } else {
            reject([]);
          }
        }),
      )
      .catch(reject);
  });
// loader for agency list in async select
// TO DO : Temporary removal of Agency filter

const getAgencylist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        select: ['_id', 'company_name'],
        searchKey: inputvalue,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          // add an all select option
          if (data && data.rows && data.rows.length > 0) {
            let AllElement = { _id: 0, company_name: 'ALL' };
            let newArray = [AllElement, ...data.rows];
            resolve(
              newArray.map(({ _id, company_name }) => ({
                value: _id,
                label: company_name,
              })),
            );
          } else {
            // No options found
            resolve([]);
          }
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}

const connectedAllShiftsList = connect(mapStateToProps)(AllShiftsList);
export { connectedAllShiftsList as AllShiftsList };
