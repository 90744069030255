/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { LoadingImg, PaginationNew } from '../../_components';
import { apiConstants } from '../../_constants';
import { authHeader } from '../../_helpers';
import { employerUserService } from '../../_services';
import AddNewUserModal from './AddNewModal';
import AllocateBusinessUnitModal from './AllocateModal';

const EmployerSettingsUser = () => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showAllocateModal, setShowAllocateModal] = useState(false);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });
  const [activateQuery, setActivateQuery] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [nodata, setNoData] = useState(false);
  const [isActivateLoading, setIsActivateLoading] = useState(false);

  const [employerUsers, setEmployerUsers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(current => !current);
  };
  const [error, setError] = useState(null);
  const [employeesRows, setEmployeesRows] = useState([]);
  const [employeesData, setEmployeesData] = useState({ rows: [] });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [userSuccessMessage, setUserSuccessMessage] = useState(false);
  useEffect(() => {
    getAllEmployerUsers();
  }, []);

  const handleEditClick = employee => {
    setSelectedEmployee(employee);
    setEditModal(true);
  };

  useEffect(() => {
    if (query.business_unit_id) {
      getAllEmployerUsers();
    }
  }, [query.business_unit_id]);

  const handleBusinessUnitChange = selectedOption => {
    if (selectedOption.value === 'all') {
      delete query.business_unit_id;
      getAllEmployerUsers();
    } else {
      setQuery({ ...query, business_unit_id: selectedOption.value });
    }
  };

  const handleUserAdd = () => {
    getAllEmployerUsers();
  };

  const handleChange = event => {
    const { name, value } = event.target;

    if (name === 'searchKey') {
      query.searchKey = value;
      setQuery(query);
      getAllEmployerUsers();
    }
  };

  const userData = JSON.parse(localStorage.getItem('user'));
  const employerId = userData.employer_id;

  const getBusinessUnitList = inputvalue => {
    return fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        employer_id: employerId,
        searchKey: inputvalue,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        let options = data.rows.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        if (options.length === 0) {
          return [];
        }
        options.unshift({ value: 'all', label: 'All' });
        return options;
      });
  };

  const customSelectStyles = {
    control: provided => ({
      ...provided,
      zIndex: '1',
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      fontSize: '14px',
      lineHeight: '25px',
      innerWidth: '25px',
    }),
  };

  const handleSuccess = () => {
    setShowAllocateModal(false);
    setSuccessMessage(true);
    setTimeout(() => {
      setSuccessMessage(false);
    }, 5000);
  };

  const handleUserSuccess = actionType => {
    setUserSuccessMessage(true);
    if (actionType === 'create') {
      setUserSuccessMessage('Successfully Created');
    } else if (actionType === 'edit') {
      setUserSuccessMessage('Successfully Edited');
    }
    setTimeout(() => {
      setUserSuccessMessage(false);
    }, 5000);
  };

  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = employeesData => {
    let employeesList = [];
    let prevName = '';
    for (
      let j = 0;
      employeesData && employeesData.rows && j < employeesData.rows.length;
      j = j + 1
    ) {
      let permissions = employeesData.rows[j].permissions
        ? employeesData.rows[j].permissions
        : [];

      let hasAllPermissions =
        permissions?.includes(1) &&
        permissions?.includes(2) &&
        permissions?.includes(3) &&
        permissions?.includes(4);

      let currentName = employeesData.rows[j]['Employer_user.name'];
      let isDifferentName = currentName !== prevName;

      employeesList.push(
        <tr key={j}>
          <td data-title="User Name" className="td-flx-user-settings-table">
            {isDifferentName && <div>{currentName}</div>}
          </td>
          <td data-title="Unit Name">
            <div className="td-flx-user-settings-table bus-unit-name-settings-usr">
              {employeesData.rows[j]['Businessunit.name']}
            </div>
          </td>
          {/* <td data-title="Location">
            <div className="user-settings-postcode td-flx-user-settings-table">
              {employeesData.rows[j]['Employer_user.postcode']}
            </div>
            <div className="user-settings-address">
              {employeesData.rows[j]['Employer_user.address']}
            </div>
          </td> */}
          <td data-title="Contact person ">
            <div className="flx-settings-user-details-table-container d-flex justify-content-start">
              <div className="flx-email-number">
                <p
                  className="flx-settings-email"
                  style={{ fontWeight: '500', color: 'black' }}
                >
                  {employeesData.rows[j]['Employer_user.email']}
                </p>
                <p
                  className="flx-settings-number"
                  style={{ fontWeight: '500', color: 'black' }}
                >
                  {employeesData.rows[j]['Employer_user.phone']}
                </p>
                <p
                  className="flx-settings-number"
                  style={{ fontWeight: '500', color: 'black' }}
                >
                  <span className="d-flex mt-1">
                    <input
                      type="checkbox"
                      checked={!!employeesData.rows[j].send_invoice}
                      disabled={!employeesData.rows[j].send_invoice}
                      style={{
                        marginRight: '10px',
                        pointerEvents: 'none',
                      }}
                    />
                    Receive Invoice
                  </span>
                </p>
              </div>
            </div>
          </td>
          <td
            data-title="Access type pointer"
            // onClick={() => handleEditClick(employeesData.rows[j])}
          >
            {hasAllPermissions ? (
              <div className="text-left pl-2 highlighted">Full Access</div>
            ) : (
              <>
                {(permissions.includes(1) || permissions.includes(2)) && (
                  <div className="flx-toggle-container-add-new-user">
                    <div
                      className="d-flex justify-content-start"
                      style={{ pointerEvents: 'none' }}
                    >
                      <div className="mb-1">
                        {/* <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`agencyShift_${j}`}
                          checked={
                            permissions.includes(1) || permissions.includes(2)
                          }
                        /> */}
                        <label
                          className={`toggle-label ${
                            permissions.includes(1) || permissions.includes(2)
                              ? 'highlighted'
                              : 'light'
                          }`}
                          htmlFor={`agencyShift_${j}`}
                          // style={{
                          //   fontWeight: '400',
                          //   fontSize: '12px',
                          // }}
                        >
                          Agency Shift
                        </label>
                      </div>
                    </div>
                    <div className="">
                      {/* Add toggle bars for this permission */}
                      <div
                        className="toggle-bar mb-2"
                        style={{
                          cursor: 'default',
                        }}
                        onClick={toggleActive}
                      >
                        {/* <div
                          className={`toggle-circle ${
                            isActive || permissions.includes(1) ? 'active' : ''
                          }`}
                        ></div> */}
                        <span
                          className={`toggle-label ${
                            permissions.includes(1) ? 'highlighted' : 'light'
                          }`}
                        >
                          Post Vacancy
                        </span>
                        <span
                          className={`toggle-label ${
                            permissions.includes(2) ? 'highlighted' : 'light'
                          }`}
                          style={{
                            borderLeft: '1px solid #6D6969',
                            paddingLeft: '10px',
                          }}
                        >
                          Authorise Shift
                        </span>
                      </div>
                      {/* <div
                        className="toggle-bar mb-2"
                        style={{ cursor: 'default' }}
                        onClick={toggleActive}
                      >
                        <div
                          className={`toggle-circle ${
                            isActive || permissions.includes(2) ? 'active' : ''
                          }`}
                        ></div>
                      </div> */}
                    </div>
                  </div>
                )}
                {permissions.map((permission, index) => {
                  switch (permission) {
                    case 3:
                      return (
                        <div
                          className="flx-checkbox-container-settings-create-new-user d-flex justify-content-start"
                          key={index}
                        >
                          <div
                            className="d-flex justify-content-start pl-2"
                            style={{ pointerEvents: 'none' }}
                          >
                            {/* <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={`permanentShift_${j}`}
                              checked={permission === 3}
                            /> */}
                            <label
                              className={`form-check-label usr-settings-label-access ${
                                permissions.includes(3)
                                  ? 'highlighted'
                                  : 'light'
                              } `}
                              htmlFor={`permanentShift_${j}`}
                              style={{ paddingTop: '2px' }}
                            >
                              Permanent Recruitment
                            </label>
                          </div>
                        </div>
                      );
                    case 4:
                      return (
                        <div
                          className="flx-checkbox-container-settings-create-new-user d-flex justify-content-start"
                          key={index}
                        >
                          <div
                            className="d-flex justify-content-start pl-2"
                            style={{ pointerEvents: 'none' }}
                          >
                            {/* <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={`international_${j}`}
                              checked={permission === 4}
                            /> */}
                            <span
                              className={`form-check-label usr-settings-label-acces ${
                                permissions.includes(4)
                                  ? 'highlighted'
                                  : 'light'
                              }`}
                              htmlFor={`international_${j}`}
                            >
                              International Recruitment
                            </span>
                          </div>
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
              </>
            )}
          </td>

          <td>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {!isActivateLoading ? (
                <div
                  className=""
                  onClick={() =>
                    handleActivateUser(
                      {
                        id: employeesData.rows[j].id,
                        employerUserId:
                          employeesData.rows[j]['Employer_user.id'],
                        userTableId:
                          employeesData.rows[j]['Employer_user.user_id'],
                      },
                      employeesData.rows[j].status === -1 ? 1 : -1,
                    )
                  }
                >
                  <div
                    className={`switchUser ${
                      employeesData.rows[j].status === -1
                        ? 'enable pointer'
                        : 'disable pointer'
                    }`}
                  >
                    <span className="statusText">
                      {employeesData.rows[j].status === -1
                        ? 'Enable'
                        : 'Disable'}
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <LoadingImg />
                </div>
              )}
              <a
                onClick={() => handleEditClick(employeesData.rows[j])}
                className="flx-edit-link-settings-user"
              >
                <img
                  className="mt-2"
                  alt="Edit Icon"
                  src="/assets/img/Group 37408.png"
                />
              </a>
            </div>
          </td>
        </tr>,
      );
      prevName = currentName;
    }
    setEmployeesRows(employeesList);
    setEmployeesData(employeesData);
  };

  const getAllEmployerUsers = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setError(null);
    query.withPermission = true;
    setQuery(query);
    setIsLoading(true);
    employerUserService.employerUserList(queryNew).then(
      res => {
        if (res?.data?.rows?.length > 0) {
          setNoData(false);
          setEmployerUsers(res?.data?.rows);
          setListAndPagination(res?.data);
        } else if (res?.data?.rows?.length == 0) {
          setEmployerUsers([]);
          setNoData(true);
        }
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);

        setError('Error fetching user list. Please try again.');
      },
    );
  };

  const handleActivateUser = (item, status) => {
    activateQuery.status = status;
    activateQuery.id = item.id;
    activateQuery.employerUserId = item.employerUserId;
    activateQuery.userTableId = item.userTableId;
    setActivateQuery(activateQuery);
    setIsActivateLoading(true);
    setIsLoading(true);
    setIsActivateLoading(true);
    employerUserService.activateUser(activateQuery).then(
      () => {
        setIsActivateLoading(false);
        getAllEmployerUsers(query.pageVo.pageNo);
        setIsActivateLoading(false);
      },
      () => {
        setIsLoading(false);
        setIsActivateLoading(false);
        setError('Error updating the user status. Please try again.');
      },
    );
  };

  return (
    <div
      className="col-12 flx-emp-profile-main-form"
      style={{ width: '60rem' }}
    >
      <h2 className="flx-company-info-form-heading">Users</h2>

      <div className="flx-emp-profile-settings-container">
        <div className="row mb-3 mt-3">
          <div className="col">
            <div className="input-group">
              <span className="input-group-text search-icon-settings-area">
                <i className="fa fa-search flx-settings-search-icon"></i>
              </span>
              <input
                type="text"
                className="form-control flx-search-box"
                name="searchKey"
                onChange={handleChange}
                placeholder="Search"
              />
            </div>
          </div>
          <div className="businessunitfilter px-2">
            <AsyncSelect
              styles={customSelectStyles}
              name="businessUnit"
              placeholder={'Select Business Unit'}
              cacheOptions
              defaultOptions
              className=""
              noOptionsMessage={() => 'No results found'}
              loadOptions={getBusinessUnitList}
              onChange={handleBusinessUnitChange}
            />
          </div>

          <div className="pointer flx-alloc-user-bus-unit-container col d-flex justify-content-evenly ml-5">
            <img
              src="/assets/img/alloc-business-unit.svg"
              className="alloc-bus-unit-img d-inline"
              onClick={() => setShowAllocateModal(true)}
            ></img>
            <span
              className="alloc-bus-unit-text d-inline"
              onClick={() => setShowAllocateModal(true)}
            >
              {' '}
              Allocate Business Unit
            </span>

            <button
              className="btn btn-create-user-settings"
              onClick={() => setShowModal(true)}
            >
              <i className="flx-plus-icon-create-user fa fa-plus "></i>
              Create User
            </button>
          </div>
        </div>
        {employerUsers.length != 0 && !nodata && (
          <div className="pagination-top user-list-pagination mt-0">
            <PaginationNew
              pageOnClick={data => {
                getAllEmployerUsers(data);
              }}
              paginationData={employeesData}
            />
          </div>
        )}
        {employerUsers.length > 0 && (
          <>
            <div className="flx-table-sett-user-responsive">
              <table className="table " style={{ borderRadius: '10px' }}>
                <thead>
                  <tr className="fw-bolder fs-1">
                    <th className="fw-bold flx-emp-user">Name</th>
                    <th className="flx-emp-user">Business Unit Name</th>
                    {/* <th>Location</th> */}
                    <th className="flx-emp-user">Contact Details</th>
                    <th className="flx-emp-user">Access Type</th>
                    <th className="flx-emp-user">Action</th>
                  </tr>
                </thead>
                <tbody>{employeesRows}</tbody>
              </table>
            </div>
            {employeesData &&
              employeesData.rows &&
              employeesData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </>
        )}
        {nodata && query.searchKey && employerUsers.length === 0 && (
          <p style={{ color: 'red' }}>No result found.</p>
        )}

        {employerUsers.length == 0 &&
          nodata &&
          !query.searchKey &&
          !isLoading && (
            <div className="flx-emp-profile-settings-container">
              <div
                className="flx-add-bus-unit-text-container pointer"
                onClick={() => setShowModal(true)}
              >
                <i className="flx-plus-business-unit-icon fa fa-plus"></i>
                <p className="flx-text-plus-bus-unit-text">
                  You haven&apos;t added any employer user yet
                </p>
              </div>
            </div>
          )}
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-popup">
              <LoadingImg />
            </div>
          </div>
        )}
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {/* {employerUsers.length != 0 && !nodata && (
        <div className="card-footer">
          <Pagination
            pageOnClick={data => {
              getAllEmployerUsers(data);
            }}
            paginationData={employeesData}
          />
        </div>
      )} */}

      {showModal && (
        <div className="modal-overlay">
          <div className="popup">
            <AddNewUserModal
              setShowModal={setShowModal}
              title="Create New User"
              handleUserAdd={handleUserAdd}
              onSuccess={() => handleUserSuccess('create')}
            />
          </div>
        </div>
      )}
      {userSuccessMessage && (
        <div className="alert alertboxCustomSuccess">
          <span className="alert-close">X</span>
          <p>{userSuccessMessage}</p>
        </div>
      )}
      {showAllocateModal && (
        <div className="modal-overlay">
          <div className="popup">
            <AllocateBusinessUnitModal
              title="Allocate Business Unit"
              setShowAllocateModal={setShowAllocateModal}
              handleUserAdd={handleUserAdd}
              onSuccess={handleSuccess}
            />
          </div>
        </div>
      )}
      {successMessage && (
        <div className="alert alertboxCustomSuccess">
          <span className="alert-close">X</span>
          <p>Successfully Allocated</p>
        </div>
      )}
      {editModal && (
        <div className="modal-overlay hide-scrollbar">
          <div className="popup">
            <AddNewUserModal
              setShowModal={setEditModal}
              employee={selectedEmployee}
              handleUserAdd={handleUserAdd}
              title="Edit User Details"
              onSuccess={() => handleUserSuccess('edit')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployerSettingsUser;
