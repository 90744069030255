import { MsalProvider } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import GoogleLogin from '../AuthPage/GoogleLogin';
import MicrosoftAuth from '../AuthPage/MicrosoftAuth';
import { LoadingImg } from '../_components';
import { messageConstant, roleConstant } from '../_constants';
import { miscService, msalInstance, userService } from '../_services';

const RegisterPage = () => {
  const { id } = useParams();
  const [user, setUser] = useState({
    company_name: '',
    phone: '',
    email: '',
    password: '',
    temp_token: '',
    role: roleConstant.EMPLOYER,
  });
  const [error, setError] = useState({ common: '' });
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [isLoding, setLoading] = useState(false);
  const [socialAuthIsLoading, setSocialAuthIsLoading] = useState(false);
  const [otpSendSuccessfullyEmail, setotpSendSuccessfullyEmail] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLaodingOtpSentEmail, setIsLaodingOtpSentEmail] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [timerLeft, setTimeLeft] = useState(120);
  const location = useLocation();

  const EMAIL = 'email';
  const PHONE = 'phone';

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      try {
        setError({
          email: '',
          company_name: '',
          phone: '',
        });
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');
        const shareableLinkDetails = await userService.inviteLinkVerify(id);
        console.log(shareableLinkDetails, 123);
        user.email = shareableLinkDetails.data.user.email;
        user.company_name = shareableLinkDetails.data.user.name;
        user.phone = shareableLinkDetails.data.user.phone;
        user.role = shareableLinkDetails.data.user.role;
        user.temp_token = shareableLinkDetails.data.temp_token;
        setFetching(false); // Set loading to false after successful response
      } catch (err) {
        console.error('Error verifying invite link:', err);
        setError(err); // Set error if request fails
        setFetching(false); // Stop loading if an error occurs
      }
    };

    if (id !== null) {
      fetchData(); // Call fetchData only if id is valid
    }
  }, [id]);

  console.log(user, `userDetails`);
  useEffect(() => {
    // Initialize or reset state when component mounts
    setSocialAuthIsLoading(false);
    return () => {
      setSocialAuthIsLoading(false);
    };
  }, [location.key]); // Depend on location.key to re-mount when navigating

  const handleChange = event => {
    const { name, value } = event.target;
    const numericValue = value.replace(/[^0-9]/g, '');

    const updatedError = {
      otp_error: '',
      ...error,
    };

    // if (name === EMAIL && value.length > 320) {
    //   return;
    // }

    if (name === 'company_name') {
      if (value === '') {
        updatedError.company_name = messageConstant.COMPANY_REQUIRED;
        updatedError.isError = true;
      } else {
        updatedError.company_name = '';
      }
    }

    if (name === EMAIL) {
      setotpSendSuccessfullyEmail(false);
      setTimeLeft(0);
      setTimerOn(false);
      const checkEmailValidation = miscService.emailVerify(value);
      setEmailValidation(checkEmailValidation);
      if (user.temp_token) {
        setotpSendSuccessfullyEmail(false);
        setTimeLeft(0);
        setTimerOn(false);
        user.otp = '';
        user.temp_token = '';
        if (!checkEmailValidation) {
          updatedError.email = messageConstant.INVALID_EMAIL;
          updatedError.isError = true;
        }
      } else if (value === '') {
        updatedError.email = messageConstant.EMAIL_REQUIRED;
        updatedError.isError = true;
        setotpSendSuccessfullyEmail(false);
        setTimeLeft(0);
        setTimerOn(false);
        user.otp = '';
        user.temp_token = '';
      } else if (!checkEmailValidation) {
        updatedError.email = messageConstant.INVALID_EMAIL;
        updatedError.isError = true;
      } else {
        updatedError.email = '';
      }
    }
    if (name === 'password') {
      if (value === '') {
        updatedError.password = messageConstant.PASSWORD_REQUIRED;
        updatedError.isError = true;
      } else if (value.length >= 1 && value.length < 8) {
        updatedError.password = messageConstant.PASSWORD_LENGTH;
        updatedError.isError = true;
      } else if (value.length > 32) {
        return;
      } else {
        updatedError.password = '';
      }
    }

    if (name === PHONE) {
      if (numericValue.charAt(0) === '0') {
        // error message
        if (numericValue.length !== 11) {
          updatedError.phone = messageConstant.VALID_PHONE;
          updatedError.isError = true;
        }
        updatedError.phone = '';
        if (numericValue.length > 11) {
          return;
        }
      } else {
        if (numericValue.length > 11) return;
      }
    }

    if (name === 'otp') {
      const otpValue = value.replace(/[^0-9]/g, '');
      if (otpValue.length > 4) {
        updatedError.otp = messageConstant.OTP_EXP_OR_INVALID;
        updatedError.isError = true;
        return;
      }
      if (value.length > 4) {
        return;
      }
      setUser({
        ...user,
        otp: otpValue,
      });

      if (value.length === 4) {
        handleVerifyOtp(value, EMAIL);
      }
    } else if (name === PHONE) {
      setUser({
        ...user,
        [name]: numericValue,
      });
    } else {
      setUser({
        ...user,
        [name]: value,
      });
    }
    setError(updatedError);
  };

  const handleBlur = () => {
    error.isError = false;

    if (!user.company_name) {
      error.company_name = messageConstant.COMPANY_REQUIRED;
      error.isError = true;
    }
    if (!user.email) {
      error.email = messageConstant.EMAIL_REQUIRED;
      error.isError = true;
    }
    if (!user.password) {
      error.password = messageConstant.PASSWORD_REQUIRED;
      error.isError = true;
    }
    if (error.isError) {
      setError({ ...error });
      return;
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    error.isError = false;

    if (!user.email) {
      error.email = messageConstant.EMAIL_REQUIRED;
      error.isError = true;
    }
    if (!user.company_name) {
      error.company_name = messageConstant.COMPANY_REQUIRED;
      error.isError = true;
    }
    if (!user.password) {
      error.password = messageConstant.PASSWORD_REQUIRED;
      error.isError = true;
    }
    if (!user.temp_token) {
      error.common = messageConstant.VERIFY_EMAIL;
      error.isError = true;
    }
    if (error.isError) {
      setError({ ...error });
      return;
    }

    try {
      setLoading(true);
      const userData = {
        company_name: user.company_name,
        phone: user.phone,
        email: user.email,
        password: user.password,
        temp_token: user.temp_token,
      };
      const res = await userService.registrationWithToken(userData);
      if (res.error_code === 0) {
        // Store data in local storage
        localStorage.setItem('user', JSON.stringify(res.data.data));

        if (parseInt(res.data.data.role) === 4) {
          window.location.href = '/dashboardemployer';
        } else {
          window.location.href = '/login';
        }
      }
    } catch (error) {
      setLoading(false);
      if (error === messageConstant.PHONE_ALREADY_USED) {
        setError({
          ...error,
          validationPhone: messageConstant.PHONE_ALREADY_USED,
          isError: true,
        });
      } else {
        setError({
          ...error,
          common: messageConstant.PROCESS_ERROR,
          isError: true,
        });
      }
    }
    setLoading(false);
  };

  const handleSendOTP = async isRendOtp => {
    // Email validation
    if (!user.email || !miscService.emailVerify(user.email)) {
      setError({ ...error, email: messageConstant.INVALID_EMAIL });
      return;
    }
    if (!user.email) {
      setError({
        ...error,
        email: messageConstant.EMAIL_REQUIRED,
      });
      return;
    } else {
      setError({
        ...error,
        email: '',
        phone: '',
        otp_error: '',
      });
    }
    setIsLaodingOtpSentEmail(true);
    setTimerOn(true);
    setotpSendSuccessfullyEmail(true);
    setTimeLeft(120);

    try {
      await userService.sendOtpRegister({
        email: user.email,
        role: user.role,
        isRendOtp: isRendOtp,
      });

      setIsLaodingOtpSentEmail(false);
      setIsLoadingVerify(false);
    } catch (error) {
      setIsLoadingVerify(false);
      if (
        error == messageConstant.EMAIL_ALREADY_USED ||
        error == messageConstant.EMAIL_ALREADY_USED_FORGET_PASSWORD
      ) {
        setError({
          ...error,
          email: typeof error == 'string' ? error : error.message,
        });
      } else if (error == messageConstant.PHONE_ALREADY_USED) {
        setError({
          ...error,
          phone: typeof error == 'string' ? error : error.message,
        });
      } else {
        setError({
          ...error,
          common: typeof error == 'string' ? error : error.message,
        });
      }
      console.error('Failed to verify the OTP');
      setIsLaodingOtpSentEmail(false);
      setTimerOn(false);
      setotpSendSuccessfullyEmail(false);
    }
  };

  const handleVerifyOtp = async otp => {
    if (isLoadingVerify) {
      return;
    }
    setError({
      ...error,
      common: '',
      otp_error: '',
    });
    setIsLoadingVerify(true);

    try {
      const userData = {
        email: user.email,
        role: parseInt(user.role),
        phone: '44' + user.phone,
        otp: otp, // Replace with the actual OTP value
      };
      const res = await userService.verifyOtpRegister(userData);

      if (res.data && res.error_code == 0) {
        setUser({
          ...user,
          temp_token: res.data.temp_token,
        });
        setotpSendSuccessfullyEmail(false);
      }
      setIsLoadingVerify(false);
      setTimeLeft(0);
    } catch (error) {
      setIsLoadingVerify(false);
      setotpSendSuccessfullyEmail(true);
      setIsLaodingOtpSentEmail(false);
      setError({
        ...error,
        otp_error: messageConstant.OTP_EXP_OR_INVALID,
      });
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      if (timerLeft > 0) {
        setTimeLeft(timerLeft - 1);
      }

      if (timerLeft === 0) {
        setTimerOn(false);
        clearInterval(timerId);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [timerLeft]);

  useEffect(() => {
    setSocialAuthIsLoading(false);
    return () => {
      setSocialAuthIsLoading(false);
    };
  }, []);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  //
  return (
    <div className="container-fluid">
      <div className="text-dark mt-4">
        <div className="  logo-box-auth">
          <div className="logo-box logo-box2">
            <img src="/assets/img/recsy-temp-logo.svg" />
          </div>
        </div>
        <div className="col-lg-3 col-md-5 m-auto">
          <div className="auth-box">
            <h3 className="mb-0 auth-title">Employer Sign Up</h3>
            <h6 className=" auth-subtitle">
              {"Let's hire your next great candidate"}
            </h6>

            {/* <form name="form" autoComplete="off"> */}
            <div
              className={
                'form-group' + (error.company_name ? ' has-error' : '')
              }
            >
              <label htmlFor="company_name" className="mt-4">
                Company Name <span className="help-block"> * </span>
              </label>
              <div className="d-flex bg-white flex-row border-0 input-group input-wrap-with-btn">
                <input
                  type="text"
                  className="form-control flx-common-input-fields pl-1 input-textarea input-wrap-with-btn"
                  name="company_name"
                  placeholder="Company Name"
                  onBlur={handleBlur}
                  value={user.company_name}
                  onChange={handleChange}
                />
              </div>
              <div className="help-block">{error.company_name}</div>
            </div>
            <div className={'form-group' + (error.email ? '' : '')}>
              <label htmlFor="email">
                Email <span className="help-block"> * </span>
              </label>
              <div className="d-flex bg-white flex-row input-group px-2 input-wrap-with-btn">
                <input
                  type="email"
                  pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                  className="form-control border-0 m-auto input-textarea flx-sign-up-email"
                  name="email"
                  placeholder="Email"
                  onBlur={handleBlur}
                  value={user.email}
                  onChange={handleChange}
                  disabled={id !== null}
                />
                {!user.temp_token ? (
                  <button
                    onClick={() => {
                      otpSendSuccessfullyEmail
                        ? handleSendOTP(true)
                        : handleSendOTP();
                    }}
                    className={`btn border m-auto ${
                      emailValidation ? 'verify-btn' : 'bg-light text-dark'
                    }`}
                    disabled={
                      !emailValidation || isLaodingOtpSentEmail || timerOn
                    }
                  >
                    {otpSendSuccessfullyEmail && !timerOn
                      ? 'Resend OTP'
                      : timerOn
                      ? `${timerLeft} sec`
                      : 'Verify'}
                  </button>
                ) : (
                  <span className="text-success m-auto p-2">Verified</span>
                )}
              </div>
              <span className="socialAuthIsLoading">
                {socialAuthIsLoading && <LoadingImg />}
                {fetching && <LoadingImg />}
              </span>
              <div className="help-block">{error.email}</div>
              {/*After otp......*/}

              {otpSendSuccessfullyEmail && (
                <div className="">
                  <section className="">
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-12">
                        <div className="card border-0">
                          <div className="text-center">
                            <p
                              className=" p-2"
                              style={{
                                width: '100%',
                                fontSize: '14px',
                                fontFamily: 'Manrope',
                                fontWeight: '500',
                              }}
                            >
                              We have send an OTP to this email
                            </p>
                            <div
                              className={
                                'row justify-content-center ' +
                                (error.otp_error ? 'otp_error' : '')
                              }
                            >
                              <div className="col-lg-6 col-md-10 ">
                                <input
                                  type="text"
                                  step="any"
                                  className="form-control border pl-1 fw-500 input-textarea text-center"
                                  placeholder="OTP"
                                  name="otp"
                                  value={user.otp}
                                  onChange={handleChange}
                                />
                                {/* {timerOn && (
                                  <p
                                    className="flx-general-text mt-2"
                                    style={{
                                      width: '100%',
                                      fontSize: '14px',
                                      fontFamily: 'Manrope',
                                      fontWeight: '400',
                                    }}
                                  >
                                    Request resend OTP in{' '}
                                    {timerLeft < 10
                                      ? `0${timerLeft}`
                                      : timerLeft}{' '}
                                    sec
                                  </p>
                                )} */}
                              </div>
                            </div>
                            {!!error.otp_error && (
                              <div
                                className="alert alert-danger mt-2"
                                role="alert"
                              >
                                {error.otp_error}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}
            </div>
            <div className={'form-group' + (error.phone ? '' : '')}>
              <label htmlFor="phone">Phone Number</label>
              <div className="d-flex flex-row bg-white input-group px-2 input-wrap-with-btn">
                <span
                  className="m-auto border-right px-2"
                  style={{ fontSize: '13px', fontWeight: '600' }}
                >
                  +44
                </span>
                <input
                  type="text"
                  step="any"
                  className="form-control border border-0 pl-1 fw-500 input-textarea flx-input-phone-number"
                  placeholder="0999999999"
                  name="phone"
                  onBlur={handleBlur}
                  value={user.phone}
                  onChange={handleChange}
                />
                {/* {!user.temp_token ? (
                  <button
                    onClick={() => handleSendOTP(OTP_TYPE_PHONE)}
                    className={
                      'btn  border  m-auto ' +
                      (user.phone ? 'verify-btn ' : 'bg-light color-primary')
                    }
                  >
                    {!isLaodingOtpSentPhone ? 'Verify' : 'Loading...'}
                  </button>
                ) : (
                  // collor green and text whit
                  <button
                    className={
                      verifiedPhone
                        ? 'text-success m-auto p-2'
                        : 'rounded p-1 border m-auto bg-light color-primary'
                    }
                  >
                    {verifiedPhone ? 'verified' : 'verify'}
                  </button>
                )} */}
              </div>
              {error.phone && (
                <div className="text-danger mt-1">{error.phone}</div>
              )}
              {/*After otp......*/}
              {error.validationPhone && (
                <div className="help-block">{error.validationPhone}</div>
              )}
              {/* {otpSendSuccessfullyPhone && (
                <div className="">
                  <section className="">
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-12">
                        <div className="card border-0">
                          <div className="text-center">
                            <p
                              style={{
                                width: '100%',
                                fontSize: '14px',
                                fontFamily: 'Manrope',
                                fontWeight: '500',
                              }}
                            >
                              We have send an OTP to this Phone
                            </p>
                            <div
                              className={
                                'row justify-content-center ' +
                                (error.otp_error ? 'otp_error' : '')
                              }
                            >
                              <div className="col-12 col-md-5">
                                <OtpInput
                                  handleSubmit={otp =>
                                    handleVerifyOtp(otp, OTP_TYPE_PHONE)
                                  }
                                  handleChangeOtp={() => {
                                    setError({ ...error, otp_error: '' });
                                  }}
                                />
                              </div>
                            </div>
                            {!!error.otp_error && (
                              <div
                                className="alert alert-danger mt-2"
                                role="alert"
                              >
                                {error.otp_error}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )} */}
            </div>

            <label htmlFor="password">
              Create Password<span className="help-block"> * </span>
            </label>
            <div className="d-flex flex-row bg-white input-group input-wrap-with-btn">
              <input
                type={showPassword ? 'text' : 'password'}
                step="any"
                className="form-control border border-0 pl-1 fw-500 input-textarea"
                placeholder="Password"
                name="password"
                onBlur={handleBlur}
                value={user.password}
                onChange={handleChange}
              />
              <span onClick={handlePasswordToggle} className="m-auto px-2">
                {showPassword ? (
                  <img src="/assets/img/eyeclose.png" />
                ) : (
                  <img src="/assets/img/eye.png" />
                )}
              </span>
            </div>
            <div className="help-block">{error.password}</div>
          </div>
          <div className="form-group mt-4">
            <span className="color-red">{error.common}</span>
            <div>
              <button
                onClick={handleSubmit}
                className={
                  'send-btn  ' +
                  (user.password.length > 7 &&
                  user.company_name &&
                  user.email &&
                  user.temp_token
                    ? 'active-signup-btn'
                    : 'disabled')
                }
                disabled={isLoding}
              >
                {!isLoding ? 'Sign Up' : 'Saving..'}
              </button>
            </div>
          </div>
          <div className="text-center">
            <div className="row flx-continue-sign-with-row">
              <div className="col">
                <hr />
              </div>
              <div className="col-auto">
                <span className="flx-continue-sign-with">OR Continue with</span>
              </div>
              <div className="col">
                <hr />
              </div>
            </div>
          </div>

          <div className="flx-other-signup-option-container d-flex align-items-center">
            <div className="flx-google-sign-in">
              <GoogleLogin
                isRegister={true}
                showError={error =>
                  setError({
                    common: error,
                  })
                }
                showLoading={status => {
                  setSocialAuthIsLoading(status);
                }}
              />
            </div>
            <div className="flx-microsoft-sign-in">
              <MsalProvider instance={msalInstance}>
                <MicrosoftAuth
                  isRegister={true}
                  showError={msg => {
                    setError({
                      ...error,
                      common: msg,
                    });
                  }}
                  showLoading={status => {
                    setSocialAuthIsLoading(status);
                  }}
                />
              </MsalProvider>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center p-3">
        <div
          className="d-flex m-auto text-dark link-black mb-2"
          style={{ fontSize: '14px' }}
        >
          Already have an account?
          <Link
            to="/login"
            className="m-auto px-2 flx-sign-in-text"
            style={{
              textDecoration: 'underline',
            }}
          >
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { registering } = state.registration;
  return { registering };
}
const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
